import { combineReducers } from "redux";
import CollectionReducer from "./Collection/Collection.reducer";
import DashboardReducer from "./Dashboard/Dashboard.reducer";
import LoginReducer from "./Login/Login.reducer";
import ProductionReducer from "./Production/Production.reducer";
import BankReconReducer from "./BankRecon/BankRecon.reducer";

/** rootReducer
 * @fileOverview Combine  all reducer action
 * @author  Merina Joy <merina@tensaw.email>
 */

const rootReducer = combineReducers({
  login: LoginReducer,
  dashboard: DashboardReducer,
  production: ProductionReducer,
  collection: CollectionReducer,
  bankRecon: BankReconReducer,
});

export default rootReducer;
