import React, { useState, useEffect, useContext } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@material-ui/core";
// import DatePicker from "components/DatePicker";
import {
  getProvidersList,
  getServicesList,
} from "Redux/Dashboard/Dashboard.actions";
import {
  getProdGroupByProvider,
  getProdGroupByService,
} from "Redux/Production/Production.actions";
// import Line from "components/Charts/Line";
import BarGroup from "components/Charts/BarGroup";
import "./style.css";
import { productionSummaryContext } from "context/ProdSummaryContext";

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

// const halfMonths = ["January", "February", "March", "April", "May", "June"];

// const halfMonths = () => {
//   let today = new Date();
//   let d;
//   let month = [];
//   for (let i = 6; i > 0; i -= 1) {
//     d = new Date(today.getFullYear(), today.getMonth() - i, 1);
//     month.push(months[d.getMonth()]);
//   }
//   return month;
// };

// const allMonths = () => {
//   let today = new Date();
//   let d;
//   let month = [];
//   for (let i = 12; i > 0; i -= 1) {
//     d = new Date(today.getFullYear(), today.getMonth() - i, 1);
//     month.push(months[d.getMonth()]);
//   }
//   return month;
// };

const shortMonths = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
function Production(props) {
  const clinic_id = localStorage.getItem("clinic_id");
  // const [period, setPeriod] = useState(1);
  // const [chartProviders, setChartProviders] = useState([]);
  // const [chartServices, setChartServices] = useState([]);
  // const [providers, setProviders] = useState([
  //   {
  //     name: "All providers",
  //     value: -1,
  //   },
  // ]);
  // const [providerNames, setproviderNames] = useState([]);
  // const [provider, setProvider] = useState(-1);
  // const [services, setServices] = useState([
  //   {
  //     name: "All services",
  //     value: -1,
  //   },
  // ]);
  // const [service, setService] = useState(-1);
  // const [group, setGroup] = useState(1);
  // const [rows, setRows] = useState([]);
  // const [halfRows, setHalfRows] = useState([]);
  // const [totals, setTotals] = useState([]);
  // const [halfTotals, setHalfTotals] = useState([]);
  // const [chartData, setChartData] = useState([]);
  // const [halfChartData, setHalfChartData] = useState([]);
  // const [values, setValues] = useState({
  //   period: 1,
  //   group: 1,
  //   provider: -1,
  //   service: -1,
  // });
  const {
    period,
    setPeriod,
    chartProviders,
    setChartProviders,
    chartServices,
    setChartServices,
    providers,
    setProviders,
    provider,
    setProvider,
    services,
    setServices,
    service,
    setService,
    group,
    setGroup,
    rows,
    setRows,
    halfRows,
    setHalfRows,
    totals,
    setTotals,
    halfTotals,
    setHalfTotals,
    chartData,
    setChartData,
    halfChartData,
    setHalfChartData,
    values,
    setValues,
  } = useContext(productionSummaryContext);

  const periods = [
    { name: "Last 6 months", value: 1 },
    { name: "Last 12 months", value: 2 },
  ];

  const groups = [
    { name: "Group by Provider", value: 1 },
    { name: "Group by Service", value: 2 },
  ];

  const handleProviderChange = (event) => {
    setProvider(event.target.value);
  };

  const handleServiceChange = (event) => {
    setService(event.target.value);
  };

  const handleGroupChange = (event) => {
    setGroup(event.target.value);
  };

  const handlePeriodChange = (event) => {
    setPeriod(event.target.value);
  };

  const handleFilter = () => {
    setValues({
      period: period,
      group: group,
      provider: provider,
      service: service,
    });
    if (group === 1) {
      props.getProdGroupByProvider({
        clinicId: clinic_id,
        serviceId: service === -1 ? -1 : service,
      });
    } else if (group === 2) {
      props.getProdGroupByService({
        clinicId: clinic_id,
        providerId: provider === -1 ? -1 : provider,
      });
    }
  };

  useEffect(() => {
    props.getProvidersList(clinic_id);
    props.getServicesList({
      start: 0,
      limit: 100,
    });
    if (group === 1) {
      props.getProdGroupByProvider({
        clinicId: clinic_id,
        serviceId: -1,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      props.dashboardData &&
      props.dashboardData.providersList &&
      props.dashboardData.providersList.response
    ) {
      let data = props.dashboardData.providersList.response.data;
      let providersArray = data
        .filter((item) => item.active === 1)
        .map((item) => {
          return {
            name: item.firstName + " " + item.middleName + " " + item.lastName,
            value: item.id,
          };
        });
      // providersArray.unshift({
      //   name: "All providers",
      //   value: -1,
      // });
      let newArray = [
        {
          name: "All providers",
          value: -1,
        },
        ...providersArray,
      ];
      setProviders(newArray);
      setChartProviders(
        data.map((item) => item.firstName + " " + item.lastName)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dashboardData.providersList.response]);

  useEffect(() => {
    if (
      props.dashboardData &&
      props.dashboardData.servicesList &&
      props.dashboardData.servicesList.response
    ) {
      if (props.dashboardData.servicesList.response.responseCode === 0) {
        let data = props.dashboardData.servicesList.response.data.results;
        let servicesArray = data.map((item) => {
          return {
            name: item.name,
            value: item.id,
          };
        });
        // servicesArray.unshift({
        //   name: "All services",
        //   value: -1,
        // });
        let newArray = [
          {
            name: "All services",
            value: -1,
          },
          ...servicesArray,
        ];
        setServices(newArray);
        setChartServices(servicesArray.map((item) => item.name));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dashboardData.servicesList.response]);

  useEffect(() => {
    if (
      props.prodData &&
      props.prodData.ProdGroupByProvider &&
      props.prodData.ProdGroupByProvider.response
    ) {
      if (props.prodData.ProdGroupByProvider.response.responseCode === 0) {
        let data = props.prodData.ProdGroupByProvider.response.data;
        console.log("hello", data);
        const getSum = (rowArray) => {
          let sum = rowArray
            .map((item) => item.billed)
            .reduce((total, current) => total + current, 0);
          return sum;
        };
        setRows(
          data
            .map((item) => {
              return {
                providerId: item.providerId,
                providerName: item.providerName,
                productivityTrendProviderServices:
                  item.productivityTrendProviderServices,
                rowTotal: getSum(item.productivityTrendProviderServices),
              };
            })
            .sort((a, b) => b.rowTotal - a.rowTotal)
            .filter((item) => item.rowTotal > 0)
        );
        setHalfRows(
          data
            .map((item) => {
              return {
                providerId: item.providerId,
                providerName: item.providerName,
                productivityTrendProviderServices:
                  item.productivityTrendProviderServices.slice(6, 12),
                rowTotal: getSum(
                  item.productivityTrendProviderServices.slice(6, 12)
                ),
              };
            })
            .sort((a, b) => b.rowTotal - a.rowTotal)
            .filter((item) => item.rowTotal > 0)
        );
        let flatArray = data
          .map((item) => item.productivityTrendProviderServices)
          .flat();
        let totalJan = 0;
        let totalFeb = 0;
        let totalMar = 0;
        let totalApr = 0;
        let totalMay = 0;
        let totalJun = 0;
        let totalJul = 0;
        let totalAug = 0;
        let totalSep = 0;
        let totalOct = 0;
        let totalNov = 0;
        let totalDec = 0;
        const getTotal = (month) => {
          let newArray = flatArray
            .filter((item) => (item.month === month ? item : null))
            .map((item) =>
              item
                ? { billed: item.billed, year: item.year, month: item.month }
                : null
            );
          let totalBilled = newArray
            .map((item) => item.billed)
            .reduce((total, current) => total + current, 0);
          if (newArray.length) {
            return {
              billed: totalBilled,
              month: month,
              year: newArray[0].year,
            };
          } else {
            return null;
          }
        };
        totalJan = getTotal(1);
        totalFeb = getTotal(2);
        totalMar = getTotal(3);
        totalApr = getTotal(4);
        totalMay = getTotal(5);
        totalJun = getTotal(6);
        totalJul = getTotal(7);
        totalAug = getTotal(8);
        totalSep = getTotal(9);
        totalOct = getTotal(10);
        totalNov = getTotal(11);
        totalDec = getTotal(12);
        let totalsArray = [
          totalJan ? totalJan : null,
          totalFeb ? totalFeb : null,
          totalMar ? totalMar : null,
          totalApr ? totalApr : null,
          totalMay ? totalMay : null,
          totalJun ? totalJun : null,
          totalJul ? totalJul : null,
          totalAug ? totalAug : null,
          totalSep ? totalSep : null,
          totalOct ? totalOct : null,
          totalNov ? totalNov : null,
          totalDec ? totalDec : null,
        ];
        setTotals(
          totalsArray
            .filter((item) => item !== null)
            .sort((a, b) => a.year - b.year)
            .map((item) => item.billed)
        );
        setHalfTotals(
          totalsArray
            .filter((item) => item !== null)
            .sort((a, b) => a.year - b.year)
            .map((item) => item.billed)
            .slice(6, 12)
        );
        let jan = { month: "Jan" };
        let feb = { month: "Feb" };
        let mar = { month: "Mar" };
        let apr = { month: "Apr" };
        let may = { month: "May" };
        let jun = { month: "Jun" };
        let jul = { month: "Jul" };
        let aug = { month: "Aug" };
        let sep = { month: "Sep" };
        let oct = { month: "Oct" };
        let nov = { month: "Nov" };
        let dec = { month: "Dec" };
        data.forEach((item) => {
          item.productivityTrendProviderServices.forEach((x, i) => {
            if (x.month === 1) {
              jan[item.providerName] = x.billed;
              jan[item.providerName + "Color"] = "hsl(1, 70%, 50%)";
              jan["id"] = i;
            }
            if (x.month === 2) {
              feb[item.providerName] = x.billed;
              feb[item.providerName + "Color"] = "hsl(1, 70%, 50%)";
              feb["id"] = i;
            }
            if (x.month === 3) {
              mar[item.providerName] = x.billed;
              mar[item.providerName + "Color"] = "hsl(1, 70%, 50%)";
              mar["id"] = i;
            }
            if (x.month === 4) {
              apr[item.providerName] = x.billed;
              apr[item.providerName + "Color"] = "hsl(1, 70%, 50%)";
              apr["id"] = i;
            }
            if (x.month === 5) {
              may[item.providerName] = x.billed;
              may[item.providerName + "Color"] = "hsl(1, 70%, 50%)";
              may["id"] = i;
            }
            if (x.month === 6) {
              jun[item.providerName] = x.billed;
              jun[item.providerName + "Color"] = "hsl(1, 70%, 50%)";
              jun["id"] = i;
            }
            if (x.month === 7) {
              jul[item.providerName] = x.billed;
              jul[item.providerName + "Color"] = "hsl(1, 70%, 50%)";
              jul["id"] = i;
            }
            if (x.month === 8) {
              aug[item.providerName] = x.billed;
              aug[item.providerName + "Color"] = "hsl(1, 70%, 50%)";
              aug["id"] = i;
            }
            if (x.month === 9) {
              sep[item.providerName] = x.billed;
              sep[item.providerName + "Color"] = "hsl(1, 70%, 50%)";
              sep["id"] = i;
            }
            if (x.month === 10) {
              oct[item.providerName] = x.billed;
              oct[item.providerName + "Color"] = "hsl(1, 70%, 50%)";
              oct["id"] = i;
            }
            if (x.month === 11) {
              nov[item.providerName] = x.billed;
              nov[item.providerName + "Color"] = "hsl(1, 70%, 50%)";
              nov["id"] = i;
            }
            if (x.month === 12) {
              dec[item.providerName] = x.billed;
              dec[item.providerName + "Color"] = "hsl(1, 70%, 50%)";
              dec["id"] = i;
            }
          });
        });

        let graphData = [
          jan,
          feb,
          mar,
          apr,
          may,
          jun,
          jul,
          aug,
          sep,
          oct,
          nov,
          dec,
        ].sort((a, b) => a.id - b.id);
        setChartData(graphData);
        setHalfChartData(graphData.slice(6, 12));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.prodData.ProdGroupByProvider.response]);

  useEffect(() => {
    if (
      props.prodData &&
      props.prodData.ProdGroupByService &&
      props.prodData.ProdGroupByService.response
    ) {
      if (props.prodData.ProdGroupByService.response.responseCode === 0) {
        let data = props.prodData.ProdGroupByService.response.data;
        // data.sort(function (a, b) {
        //   return parseInt(a.month) - parseInt(b.month);
        // });
        // data.sort(function (a, b) {
        //   return parseInt(a.year) - parseInt(b.year);
        // });
        const getSum = (rowArray) => {
          let sum = rowArray
            .map((item) => item.billed)
            .reduce((total, current) => total + current, 0);
          return sum;
        };
        setRows(
          data
            .map((item) => {
              return {
                serviceId: item.serviceId,
                serviceName: item.serviceName,
                productivityTrendProviderServices:
                  item.productivityTrendProviderServices,
                rowTotal: getSum(item.productivityTrendProviderServices),
              };
            })
            .sort((a, b) => b.rowTotal - a.rowTotal)
            .filter((item) => item.rowTotal > 0)
        );
        setHalfRows(
          data
            .map((item) => {
              return {
                serviceId: item.serviceId,
                serviceName: item.serviceName,
                productivityTrendProviderServices:
                  item.productivityTrendProviderServices.slice(6, 12),
                rowTotal: getSum(
                  item.productivityTrendProviderServices.slice(6, 12)
                ),
              };
            })
            .sort((a, b) => b.rowTotal - a.rowTotal)
            .filter((item) => item.rowTotal > 0)
        );
        let flatArray = data
          .map((item) => item.productivityTrendProviderServices)
          .flat();
        let totalJan = 0;
        let totalFeb = 0;
        let totalMar = 0;
        let totalApr = 0;
        let totalMay = 0;
        let totalJun = 0;
        let totalJul = 0;
        let totalAug = 0;
        let totalSep = 0;
        let totalOct = 0;
        let totalNov = 0;
        let totalDec = 0;
        const getTotal = (month) => {
          let newArray = flatArray
            .filter((item) => (item.month === month ? item : null))
            .map((item) =>
              item
                ? { billed: item.billed, year: item.year, month: item.month }
                : null
            );
          let totalBilled = newArray
            .map((item) => item.billed)
            .reduce((total, current) => total + current, 0);
          if (newArray.length) {
            return {
              billed: totalBilled,
              month: month,
              year: newArray[0].year,
            };
          } else {
            return null;
          }
        };
        totalJan = getTotal(1);
        totalFeb = getTotal(2);
        totalMar = getTotal(3);
        totalApr = getTotal(4);
        totalMay = getTotal(5);
        totalJun = getTotal(6);
        totalJul = getTotal(7);
        totalAug = getTotal(8);
        totalSep = getTotal(9);
        totalOct = getTotal(10);
        totalNov = getTotal(11);
        totalDec = getTotal(12);
        let totalsArray = [
          totalJan ? totalJan : null,
          totalFeb ? totalFeb : null,
          totalMar ? totalMar : null,
          totalApr ? totalApr : null,
          totalMay ? totalMay : null,
          totalJun ? totalJun : null,
          totalJul ? totalJul : null,
          totalAug ? totalAug : null,
          totalSep ? totalSep : null,
          totalOct ? totalOct : null,
          totalNov ? totalNov : null,
          totalDec ? totalDec : null,
        ];
        setTotals(
          totalsArray
            .filter((item) => item !== null)
            .sort((a, b) => a.year - b.year)
            .map((item) => item.billed)
        );
        setHalfTotals(
          totalsArray
            .filter((item) => item !== null)
            .sort((a, b) => a.year - b.year)
            .map((item) => item.billed)
            .slice(6, 12)
        );
        // let chartArray = data.map((item) => {
        //   let random = Math.floor(Math.random() * (299 - 100 + 1) + 100);
        //   let chartObj = {
        //     id: item.serviceName,
        //     color: `hsl(${random}, 70%, 50%)`,
        //     data: item.productivityTrendProviderServices.map((el) => {
        //       return { x: shortMonths[el.month - 1], y: el.billed };
        //     }),
        //   };
        //   return chartObj;
        // });
        let jan = { month: "Jan" };
        let feb = { month: "Feb" };
        let mar = { month: "Mar" };
        let apr = { month: "Apr" };
        let may = { month: "May" };
        let jun = { month: "Jun" };
        let jul = { month: "Jul" };
        let aug = { month: "Aug" };
        let sep = { month: "Sep" };
        let oct = { month: "Oct" };
        let nov = { month: "Nov" };
        let dec = { month: "Dec" };
        data.forEach((item) => {
          item.productivityTrendProviderServices.forEach((x, i) => {
            if (x.month === 1) {
              jan[item.serviceName] = x.billed;
              jan[item.serviceName + "Color"] = "hsl(1, 70%, 50%)";
              jan["id"] = i;
            }
            if (x.month === 2) {
              feb[item.serviceName] = x.billed;
              feb[item.serviceName + "Color"] = "hsl(1, 70%, 50%)";
              feb["id"] = i;
            }
            if (x.month === 3) {
              mar[item.serviceName] = x.billed;
              mar[item.serviceName + "Color"] = "hsl(1, 70%, 50%)";
              mar["id"] = i;
            }
            if (x.month === 4) {
              apr[item.serviceName] = x.billed;
              apr[item.serviceName + "Color"] = "hsl(1, 70%, 50%)";
              apr["id"] = i;
            }
            if (x.month === 5) {
              may[item.serviceName] = x.billed;
              may[item.serviceName + "Color"] = "hsl(1, 70%, 50%)";
              may["id"] = i;
            }
            if (x.month === 6) {
              jun[item.serviceName] = x.billed;
              jun[item.serviceName + "Color"] = "hsl(1, 70%, 50%)";
              jun["id"] = i;
            }
            if (x.month === 7) {
              jul[item.serviceName] = x.billed;
              jul[item.serviceName + "Color"] = "hsl(1, 70%, 50%)";
              jul["id"] = i;
            }
            if (x.month === 8) {
              aug[item.serviceName] = x.billed;
              aug[item.serviceName + "Color"] = "hsl(1, 70%, 50%)";
              aug["id"] = i;
            }
            if (x.month === 9) {
              sep[item.serviceName] = x.billed;
              sep[item.serviceName + "Color"] = "hsl(1, 70%, 50%)";
              sep["id"] = i;
            }
            if (x.month === 10) {
              oct[item.serviceName] = x.billed;
              oct[item.serviceName + "Color"] = "hsl(1, 70%, 50%)";
              oct["id"] = i;
            }
            if (x.month === 11) {
              nov[item.serviceName] = x.billed;
              nov[item.serviceName + "Color"] = "hsl(1, 70%, 50%)";
              nov["id"] = i;
            }
            if (x.month === 12) {
              dec[item.serviceName] = x.billed;
              dec[item.serviceName + "Color"] = "hsl(1, 70%, 50%)";
              dec["id"] = i;
            }
          });
        });

        let graphData = [
          jan,
          feb,
          mar,
          apr,
          may,
          jun,
          jul,
          aug,
          sep,
          oct,
          nov,
          dec,
        ].sort((a, b) => a.id - b.id);
        setChartData(graphData);
        setHalfChartData(graphData.slice(6, 12));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.prodData.ProdGroupByService.response]);

  return (
    <div className="prodSummary_root">
      {/* <div className="heading">Production Summary</div> */}
      <div>
        <div className="searchbar_container">
          <div className="dropdown_wrap_container">
            <FormControl className="dropdown_wrap">
              <InputLabel>Group by</InputLabel>
              <Select value={group} onChange={(e) => handleGroupChange(e)}>
                {groups &&
                  groups.map((item, i) => (
                    <MenuItem key={i} value={item.value}>
                      {item.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </div>

          {group === 1 ? (
            <div className="dropdown_wrap_container">
              <FormControl className="dropdown_wrap">
                <InputLabel>Service</InputLabel>
                <Select
                  value={service}
                  onChange={(e) => handleServiceChange(e)}
                >
                  {services &&
                    services.map((item, i) => (
                      <MenuItem key={i} value={item.value}>
                        {item.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </div>
          ) : group === 2 ? (
            <div className="dropdown_wrap_container">
              <FormControl className="dropdown_wrap">
                <InputLabel>Provider</InputLabel>
                <Select
                  value={provider}
                  onChange={(e) => handleProviderChange(e)}
                >
                  {providers &&
                    providers.map((item, i) => (
                      <MenuItem key={i} value={item.value}>
                        {item.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </div>
          ) : (
            ""
          )}

          <div className="dropdown_wrap_container">
            <FormControl className="dropdown_wrap">
              <InputLabel>Period</InputLabel>
              <Select value={period} onChange={(e) => handlePeriodChange(e)}>
                {periods &&
                  periods.map((item, i) => (
                    <MenuItem key={i} value={item.value}>
                      {item.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </div>

          {/* <div className="dropdown_wrap_container">
            <FormControl className="dropdown_wrap">
              <InputLabel>Display</InputLabel>
              <Select value={period} onChange={(e) => handlePeriodChange(e)}>
                {periods &&
                  periods.map((item, i) => (
                    <MenuItem key={i} value={item.value}>
                      {item.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </div> */}

          <div className="Btn_wrap">
            <div className="filterBtn_wrap">
              <Button
                className="filterBtn"
                variant="contained"
                color="primary"
                onClick={() => handleFilter()}
              >
                Filter
              </Button>
            </div>
            {/* <div className="exportBtn_wrap">
              <Button
                className="exportBtn"
                variant="outlined"
                color="primary"
                //   onClick={handleExport}
              >
                Export
              </Button>
            </div> */}
          </div>
        </div>
        <div className="graph_container">
          <div className="graph_card">
            <div className="graph_heading">
              {values.group === 1
                ? values.service === -1
                  ? "Total Production"
                  : services.filter((item) => item.value === values.service)[0]
                      .name
                : values.group === 2
                ? values.provider === -1
                  ? "Total Production"
                  : providers.filter(
                      (item) => item.value === values.provider
                    )[0].name
                : ""}
            </div>
            <div className="graph_content">
              {/* <Line
                period={values.period}
                // group={group}
                chartData={chartData}
                halfChartData={halfChartData}
              /> */}
              <BarGroup
                period={values.period}
                group={values.group}
                // keys={group === 1 ? chartProviders : chartServices}
                providerNames={chartProviders}
                serviceNames={chartServices}
                chartData={chartData}
                halfChartData={halfChartData}
              />
            </div>
          </div>
        </div>
        <div className="table_container">
          <div className="appsTab__container">
            <TableContainer component={Paper} elevation={0}>
              {values.group === 1 ? (
                <Table aria-label="sticky table">
                  <TableHead>
                    {values.period === 1 ? (
                      <TableRow>
                        <TableCell className="prod_table_head_cell provider_name">
                          <TableSortLabel>
                            {values.service === -1
                              ? "Total"
                              : services.filter(
                                  (item) => item.value === values.service
                                )[0].name}
                          </TableSortLabel>
                        </TableCell>
                        {/* {halfMonths().map((item, i) => (
                          <TableCell key={i} className="prod_table_head_cell">
                            {item ? item : ""}
                          </TableCell>
                        ))} */}
                        {halfRows.length > 0
                          ? halfRows[0].productivityTrendProviderServices.map(
                              (item, i) => (
                                <TableCell
                                  key={i}
                                  className="prod_table_head_cell month_name"
                                  align="center"
                                >
                                  {item.month ? months[item.month - 1] : ""}
                                </TableCell>
                              )
                            )
                          : ""}
                      </TableRow>
                    ) : values.period === 2 ? (
                      <TableRow>
                        <TableCell className="prod_table_head_cell provider_name">
                          <TableSortLabel>
                            {values.service === -1
                              ? "Total"
                              : services.filter(
                                  (item) => item.value === values.service
                                )[0].name}
                          </TableSortLabel>
                        </TableCell>
                        {/* {allMonths().map((item, i) => (
                          <TableCell key={i} className="prod_table_head_cell">
                            {item ? item : ""}
                          </TableCell>
                        ))} */}
                        {rows.length > 0
                          ? rows[0].productivityTrendProviderServices.map(
                              (item, i) => (
                                <TableCell
                                  key={i}
                                  className="prod_table_head_cell"
                                >
                                  {item.month ? months[item.month - 1] : ""}
                                </TableCell>
                              )
                            )
                          : ""}
                      </TableRow>
                    ) : (
                      ""
                    )}
                  </TableHead>

                  {values.period === 1 ? (
                    <TableBody>
                      <TableRow className="table_sub_head_row">
                        <TableCell></TableCell>
                        {[1, 2, 3, 4, 5, 6].map((item) => (
                          <TableCell key={item} className="table_sub_row_cell">
                            <div className="table_sub_row">
                              <div className="table_sub_head_cell">Billed</div>
                              <div className="table_sub_head_cell">Paid</div>
                              <div className="table_sub_head_cell">Balance</div>
                            </div>
                          </TableCell>
                        ))}
                      </TableRow>
                      {halfRows
                        ? halfRows.map((item, i) => (
                            <TableRow key={i}>
                              <TableCell className="provider_name">
                                {item.providerName ? item.providerName : ""}
                              </TableCell>
                              {item.productivityTrendProviderServices &&
                                item.productivityTrendProviderServices.map(
                                  (element) => (
                                    <TableCell
                                      key={element.trendLineId}
                                      className="table_body_sub_row_cell"
                                    >
                                      <div className="table_body_sub_row">
                                        <div className="table_body_sub_cell">
                                          $
                                          {element.rowTotal !== 0 &&
                                          element.billed
                                            ? parseFloat(
                                                element.billed,
                                                10
                                              ).toLocaleString("en-US", {
                                                minimumFractionDigits: 2,
                                              })
                                            : "0"}
                                        </div>
                                        <div className="table_body_sub_cell">
                                          $
                                          {element.rowTotal !== 0 &&
                                          element.billed
                                            ? parseFloat(
                                                element.billed,
                                                10
                                              ).toLocaleString("en-US", {
                                                minimumFractionDigits: 2,
                                              })
                                            : "0"}
                                        </div>
                                        <div className="table_body_sub_cell">
                                          $
                                          {element.rowTotal !== 0 &&
                                          element.billed
                                            ? parseFloat(
                                                element.billed,
                                                10
                                              ).toLocaleString("en-US", {
                                                minimumFractionDigits: 2,
                                              })
                                            : "0"}
                                        </div>
                                      </div>
                                      {/* $
                                      {element.rowTotal !== 0 && element.billed
                                        ? parseFloat(
                                            element.billed,
                                            10
                                          ).toLocaleString("en-US", {
                                            minimumFractionDigits: 2,
                                          })
                                        : "0"} */}
                                    </TableCell>
                                  )
                                )}
                            </TableRow>
                          ))
                        : ""}

                      <TableRow>
                        <TableCell className="totals_name_column1">
                          TOTAL
                        </TableCell>
                        {halfTotals.map((item, i) => (
                          <TableCell key={i} className="totals_name">
                            $
                            {item &&
                              parseFloat(item, 10).toLocaleString("en-US", {
                                minimumFractionDigits: 2,
                              })}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableBody>
                  ) : values.period === 2 ? (
                    <TableBody>
                      {rows &&
                        rows.map((item, i) => (
                          <TableRow key={i}>
                            <TableCell className="provider_name">
                              {item.providerName ? item.providerName : ""}
                            </TableCell>
                            {item.productivityTrendProviderServices &&
                              item.productivityTrendProviderServices.map(
                                (element) => (
                                  <TableCell key={element.trendLineId}>
                                    $
                                    {element.billed
                                      ? parseFloat(
                                          element.billed,
                                          10
                                        ).toLocaleString("en-US", {
                                          minimumFractionDigits: 2,
                                        })
                                      : "0"}
                                  </TableCell>
                                )
                              )}
                          </TableRow>
                        ))}

                      <TableRow>
                        <TableCell className="totals_name_column1">
                          TOTAL
                        </TableCell>
                        {totals.map((item, i) => (
                          <TableCell key={i} className="totals_name">
                            $
                            {item &&
                              parseFloat(item, 10).toLocaleString("en-US", {
                                minimumFractionDigits: 2,
                              })}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableBody>
                  ) : (
                    ""
                  )}
                </Table>
              ) : values.group === 2 ? (
                <Table aria-label="sticky table">
                  <TableHead>
                    {values.period === 1 ? (
                      <TableRow>
                        <TableCell className="prod_table_head_cell provider_name">
                          <TableSortLabel>
                            {values.provider === -1
                              ? "Total"
                              : providers.filter(
                                  (item) => item.value === values.provider
                                )[0].name}
                          </TableSortLabel>
                        </TableCell>
                        {/* {halfMonths().map((item, i) => (
                          <TableCell key={i} className="prod_table_head_cell">
                            {item ? item : ""}
                          </TableCell>
                        ))} */}
                        {halfRows.length > 0
                          ? halfRows[0].productivityTrendProviderServices.map(
                              (item, i) => (
                                <TableCell
                                  key={i}
                                  className="prod_table_head_cell"
                                >
                                  {item.month ? months[item.month - 1] : ""}
                                </TableCell>
                              )
                            )
                          : ""}
                      </TableRow>
                    ) : values.period === 2 ? (
                      <TableRow>
                        <TableCell className="prod_table_head_cell provider_name">
                          <TableSortLabel>
                            {values.provider === -1
                              ? "Total"
                              : providers.filter(
                                  (item) => item.value === values.provider
                                )[0].name}
                          </TableSortLabel>
                        </TableCell>
                        {/* {allMonths().map((item, i) => (
                          <TableCell key={i} className="prod_table_head_cell">
                            {item ? item : ""}
                          </TableCell>
                        ))} */}
                        {rows.length > 0
                          ? rows[0].productivityTrendProviderServices.map(
                              (item, i) => (
                                <TableCell
                                  key={i}
                                  className="prod_table_head_cell"
                                >
                                  {item.month ? months[item.month - 1] : ""}
                                </TableCell>
                              )
                            )
                          : ""}
                      </TableRow>
                    ) : (
                      ""
                    )}
                  </TableHead>

                  {values.period === 1 ? (
                    <TableBody>
                      {halfRows &&
                        halfRows.map((item, i) => (
                          <TableRow key={i}>
                            <TableCell className="provider_name">
                              {item.serviceName ? item.serviceName : ""}
                            </TableCell>
                            {item.productivityTrendProviderServices &&
                              item.productivityTrendProviderServices.map(
                                (element) => (
                                  <TableCell key={element.trendLineId}>
                                    $
                                    {element.billed
                                      ? parseFloat(
                                          element.billed,
                                          10
                                        ).toLocaleString("en-US", {
                                          minimumFractionDigits: 2,
                                        })
                                      : "0"}
                                  </TableCell>
                                )
                              )}
                          </TableRow>
                        ))}

                      <TableRow>
                        <TableCell className="totals_name_column1">
                          TOTAL
                        </TableCell>
                        {halfTotals.map((item, i) => (
                          <TableCell key={i} className="totals_name">
                            $
                            {item &&
                              parseFloat(item, 10).toLocaleString("en-US", {
                                minimumFractionDigits: 2,
                              })}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableBody>
                  ) : values.period === 2 ? (
                    <TableBody>
                      {rows &&
                        rows.map((item, i) => (
                          <TableRow key={i}>
                            <TableCell className="provider_name">
                              {item.serviceName ? item.serviceName : ""}
                            </TableCell>
                            {item.productivityTrendProviderServices &&
                              item.productivityTrendProviderServices.map(
                                (element) => (
                                  <TableCell key={element.trendLineId}>
                                    $
                                    {element.billed
                                      ? parseFloat(
                                          element.billed,
                                          10
                                        ).toLocaleString("en-US", {
                                          minimumFractionDigits: 2,
                                        })
                                      : "0"}
                                  </TableCell>
                                )
                              )}
                          </TableRow>
                        ))}

                      <TableRow>
                        <TableCell className="totals_name_column1">
                          TOTAL
                        </TableCell>
                        {totals.map((item, i) => (
                          <TableCell key={i} className="totals_name">
                            <div className="table_body_sub_row">
                              <div className="table_body_sub_cell">
                                $
                                {item
                                  ? parseFloat(item, 10).toLocaleString(
                                      "en-US",
                                      {
                                        minimumFractionDigits: 2,
                                      }
                                    )
                                  : "0"}
                              </div>
                              <div className="table_body_sub_cell">
                                $
                                {item
                                  ? parseFloat(item, 10).toLocaleString(
                                      "en-US",
                                      {
                                        minimumFractionDigits: 2,
                                      }
                                    )
                                  : "0"}
                              </div>
                              <div className="table_body_sub_cell">
                                $
                                {item
                                  ? parseFloat(item, 10).toLocaleString(
                                      "en-US",
                                      {
                                        minimumFractionDigits: 2,
                                      }
                                    )
                                  : "0"}
                              </div>
                            </div>
                            {/* $
                            {item &&
                              parseFloat(item, 10).toLocaleString("en-US", {
                                minimumFractionDigits: 2,
                              })} */}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableBody>
                  ) : (
                    ""
                  )}
                </Table>
              ) : (
                ""
              )}
            </TableContainer>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  dashboardData: state.dashboard,
  prodData: state.production,
});

const mapDispatchToProps = (dispatch) => ({
  getProvidersList: (values) => dispatch(getProvidersList(values)),
  getServicesList: (values) => dispatch(getServicesList(values)),
  getProdGroupByProvider: (values) => dispatch(getProdGroupByProvider(values)),
  getProdGroupByService: (values) => dispatch(getProdGroupByService(values)),
});

Production.propTypes = {
  dashboardData: PropTypes.object,
  prodData: PropTypes.object,
  getProvidersList: PropTypes.func,
  getServicesList: PropTypes.func,
  getProdGroupByProvider: PropTypes.func,
  getProdGroupByService: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(Production);
