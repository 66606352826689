import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import DatePicker from "components/DatePicker";
import TablePagination from "@material-ui/core/TablePagination";
import {
  Button,
  Dialog,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Step,
  StepLabel,
  Stepper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
} from "@material-ui/core";
import {
  getPayments,
  updateStatus,
  updatePostedDate,
  exportPayments,
  downloadPayments,
  getExportStatus,
} from "Redux/BankRecon/BankRecon.actions";
import toast from "react-hot-toast";
import "./style.css";
import { bankReconContext } from "context/BankReconContextProvider";
function BankRecon(props) {
  const clinic_id = localStorage.getItem("clinic_id");
  const {
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    rows,
    setRows,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    totalCount,
    setTotalCount,
    fileName,
    setFileName,
    hideBtn,
    setHideBtn,
    values,
    setValues,
  } = useContext(bankReconContext);

  // Download Progress

  const [activeStep, setActiveStep] = useState(0);
  const [stepFailed, setStepFailed] = useState(false);
  // const [openProgress, setOpenProgress] = useState(false);
  const steps = stepFailed
    ? ["Starting", "Exporting", "Extracting", "Failed"]
    : ["Starting", "Exporting", "Extracting", "Completed"];

  const isStepFailed = (step) => {
    return step === 3;
  };

  /* state for 'export' modal */
  const [openExport, setOpenExport] = useState(false);

  const handleOpenExport = () => {
    setOpenExport(true);
    setActiveStep(0);
  };
  const handleCloseExport = () => {
    setOpenExport(false);
    setActiveStep(0);
  };
  /******************************* */

  const DateFormat = (date) => {
    if (date) {
      let d = date.split("-");
      return `${d[1]}/${d[2]}/${d[0]}`;
    }
    return;
  };

  const formatDate = (date) => {
    if (date === null) {
      return null;
    } else if (date === "") {
      return "";
    }
    const d = new Date(date);
    let month = "" + (d.getMonth() + 1);
    let day = "" + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) {
      month = "0" + month;
    }
    if (day.length < 2) {
      day = "0" + day;
    }
    if (isNaN(year)) {
      return "";
    } else {
      return [year, month, day].join("-");
    }
  };

  const unformatDate = (value) => {
    const d = value.split("-");
    const date = new Date(parseInt(d[0]), parseInt(d[1]) - 1, parseInt(d[2]));
    return date;
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setValues({ ...values, offset: page });
  };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setPage(0);
  // };

  const handleStartDateChange = (e) => {
    setStartDate(e);
    setValues({
      ...values,
      postedFrom: formatDate(e),
    });
  };

  const handleEndDateChange = (e) => {
    setEndDate(e);
    setValues({
      ...values,
      postedTo: formatDate(e),
    });
  };

  const handleMinAmount = (e) => {
    setValues({
      ...values,
      minAmount: e.target.value,
    });
  };

  const handleMaxAmount = (e) => {
    setValues({
      ...values,
      maxAmount: e.target.value,
    });
  };

  const handleDepositStatusFilter = (e) => {
    setValues({
      ...values,
      reconStatus: e.target.value,
    });
  };

  const handlePayorChange = (e) => {
    setValues({
      ...values,
      payor: e.target.value,
    });
  };

  const handleFilter = () => {
    props.getPayments(values);
  };

  const handlePostedDateChange = (e, item) => {
    props.updatePostedDate({
      clinicId: clinic_id,
      paymentId: item.paymentId,
      postedDate: formatDate(e),
    });
  };

  const handleDepositStatusChange = (e, item) => {
    props.updateStatus({
      clinicId: clinic_id,
      paymentId: item.paymentId,
      depositStatus: e.target.value,
    });
  };

  const handleSort = (sortId) => {
    setValues({
      ...values,
      order: values.order === "ASC" ? "DESC" : "ASC",
      sortId: sortId,
    });
    props.getPayments({
      ...values,
      order: values.order === "ASC" ? "DESC" : "ASC",
      sortId: sortId,
    });
  };

  const handleExport = () => {
    props.exportPayments({
      postedFrom: values.postedFrom,
      postedTo: values.postedTo,
      minAmount: values.minAmount,
      maxAmount: values.maxAmount,
      reconStatus: values.reconStatus,
      payor: values.payor,
    });
    // setOpenExport(true);
    handleOpenExport();
    setTimeout(() => props.getExportStatus(clinic_id), 5000);
  };

  useEffect(() => {
    if (
      props.bankData &&
      props.bankData.exportResponse &&
      props.bankData.exportResponse.response
    ) {
      if (props.bankData.exportResponse.response.responseCode === 0) {
        let data = props.bankData.exportResponse.response.data.fileName;
        setFileName(data);
        setActiveStep(1);
      } else {
        setActiveStep(3);
        setStepFailed(true);
        setHideBtn(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.bankData.exportResponse.response]);

  useEffect(() => {
    if (
      props.bankData &&
      props.bankData.exportStatus &&
      props.bankData.exportStatus.response
    ) {
      if (props.bankData.exportStatus.response.responseCode === 0) {
        let status = props.bankData.exportStatus.response.data.status;
        if (status === 1) {
          setActiveStep(4);
          setHideBtn(false);
        } else {
          const interval = setInterval(() => {
            if (status === 0) {
              setActiveStep(2);
              props.getExportStatus(clinic_id);
            } else if (status === 1) {
              setActiveStep(4);
              setHideBtn(false);
            }
          }, 5000);
          return () => clearInterval(interval);
        }
      } else {
        setActiveStep(3);
        setStepFailed(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.bankData.exportStatus.response]);

  const handleDownload = () => {
    props.downloadPayments(fileName.toString());
  };

  useEffect(() => {
    if (
      props.bankData &&
      props.bankData.downloadResponse &&
      props.bankData.downloadResponse.response
    ) {
      let blobData = props.bankData.downloadResponse.response;
      let fileType = "text/csv";
      download(blobData, fileName, fileType);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.bankData.downloadResponse.response]);

  const download = (content, fileName, contentType) => {
    var a = document.createElement("a");
    var blob = new Blob([content], {
      type: contentType,
    });
    a.href = URL.createObjectURL(blob);
    a.download = fileName;
    a.click();
    blob = null;
  };

  useEffect(() => {
    props.getPayments({
      ...values,
      postedFrom: formatDate(startDate),
      postedTo: formatDate(endDate),
    });
    setValues({
      ...values,
      postedFrom: formatDate(startDate),
      postedTo: formatDate(endDate),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(() => {
    if (
      props.bankData &&
      props.bankData.paymentsRes &&
      props.bankData.paymentsRes.response
    ) {
      if (props.bankData.paymentsRes.response.responseCode === 0) {
        let data = props.bankData.paymentsRes.response.data;
        setTotalCount(data.totalRecords);
        setRowsPerPage(data.limit);
        setRows(data.results);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.bankData.paymentsRes.response]);

  useEffect(() => {
    if (
      props.bankData &&
      props.bankData.updatedStatusRes &&
      props.bankData.updatedStatusRes.response
    ) {
      if (props.bankData.updatedStatusRes.response.responseCode === 0) {
        toast.success("Deposit Status Updated");
        props.getPayments(values);
      } else {
        toast.error("Could not update deposit status");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.bankData.updatedStatusRes.response]);

  useEffect(() => {
    if (
      props.bankData &&
      props.bankData.updatedDateRes &&
      props.bankData.updatedDateRes.response
    ) {
      if (props.bankData.updatedDateRes.response.responseCode === 0) {
        toast.success("Posted Date Updated");
        props.getPayments(values);
      } else {
        toast.error("Could not update posted date");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.bankData.updatedDateRes.response]);

  return (
    <div className="bankRecon_root">
      {/* <div className="heading">Bank Reconciliation</div> */}

      <div className="bank_searchbar_container">
        <div className="production_date_dropdown">
          <DatePicker
            required
            className="production_datePicker"
            label="Posted From"
            value={startDate}
            maxDate={new Date()}
            onChange={(e) => handleStartDateChange(e)}
          />
        </div>

        <div className="production_date_dropdown">
          <DatePicker
            required
            className="production_datePicker"
            label="Posted Till"
            value={endDate}
            maxDate={new Date()}
            onChange={(e) => handleEndDateChange(e)}
          />
        </div>

        <div className="bankRecon_amount_input">
          <TextField
            className="min_amount"
            label="Min Amount"
            type="number"
            // variant="outlined"
            value={values.minAmount}
            onChange={handleMinAmount}
            startadornment={<InputAdornment position="start">$</InputAdornment>}
            size="small"
            // labelWidth={60}
          />
        </div>

        <div className="bankRecon_amount_input">
          <TextField
            className="max_amount"
            label="Max Amount"
            type="number"
            // variant="outlined"
            value={values.maxAmount}
            onChange={handleMaxAmount}
            startAdornment={<InputAdornment position="start">$</InputAdornment>}
            size="small"
            // labelWidth={60}
          />
        </div>

        <div className="status_select_container">
          <FormControl className="status_select">
            <InputLabel>Deposit Status</InputLabel>
            <Select
              value={values.reconStatus}
              onChange={handleDepositStatusFilter}
            >
              <MenuItem value={-1}>All Status</MenuItem>
              <MenuItem value={0}>Unverified</MenuItem>
              <MenuItem value={1}>Verified</MenuItem>
              <MenuItem value={2}>Missing</MenuItem>
            </Select>
          </FormControl>
        </div>

        <div className="status_select_container">
          <FormControl className="status_select">
            <InputLabel>Payor</InputLabel>
            <Select value={values.payor} onChange={handlePayorChange}>
              {/* <MenuItem value={0}>All Payors</MenuItem> */}
              <MenuItem value={1}>Insurance</MenuItem>
              <MenuItem value={2}>Patient</MenuItem>
            </Select>
          </FormControl>
        </div>

        <div className="Btn_wrap">
          <div className="filterBtn_wrap">
            <Button
              className="filterBtn"
              variant="contained"
              color="primary"
              onClick={() => handleFilter()}
            >
              Filter
            </Button>
          </div>
          <div className="exportBtn_wrap">
            <Button
              className="exportBtn"
              variant="outlined"
              color="primary"
              onClick={handleExport}
            >
              Export
            </Button>
          </div>
        </div>
      </div>

      <div className="table_container">
        <div className="appsTab__container">
          <TableContainer
            component={Paper}
            elevation={0}
            className="prod_table_container"
          >
            <Table stickyHeader size="small" aria-label="table">
              <TableHead className="prod_table_head" elevation={0}>
                <TableRow>
                  <TableCell
                    key={1}
                    className="prod_table_head_cell posted_date"
                  >
                    <TableSortLabel
                      // active={true}
                      direction={
                        values.order === "ASC"
                          ? "asc"
                          : values.order === "DESC"
                          ? "desc"
                          : ""
                      }
                      onClick={() => handleSort(1)}
                    >
                      Posted Date
                    </TableSortLabel>
                  </TableCell>
                  <TableCell className="prod_table_head_cell">
                    <TableSortLabel
                      // active={true}
                      direction={
                        values.order === "ASC"
                          ? "asc"
                          : values.order === "DESC"
                          ? "desc"
                          : ""
                      }
                      onClick={() => handleSort(2)}
                    >
                      Payor Name
                    </TableSortLabel>
                  </TableCell>
                  <TableCell className="prod_table_head_cell">Type</TableCell>
                  <TableCell className="prod_table_head_cell">
                    Check Date
                  </TableCell>
                  <TableCell className="prod_table_head_cell">
                    Check #
                  </TableCell>
                  <TableCell className="prod_table_head_cell">
                    <TableSortLabel
                      // active={true}
                      direction={
                        values.order === "ASC"
                          ? "asc"
                          : values.order === "DESC"
                          ? "desc"
                          : ""
                      }
                      onClick={() => handleSort(3)}
                    >
                      Amount
                    </TableSortLabel>
                  </TableCell>
                  <TableCell className="prod_table_head_cell">
                    Applied
                  </TableCell>
                  <TableCell className="prod_table_head_cell">
                    Unapplied
                  </TableCell>
                  <TableCell className="prod_table_head_cell">
                    Deposit Status
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.length > 0 &&
                  rows.map((item, i) => (
                    <TableRow key={i}>
                      <TableCell className="posted_date_row">
                        {/* {DateFormat(item.createdDate)} */}
                        <DatePicker
                          required
                          className="bank_datePicker"
                          // label="Posted From"
                          size="small"
                          value={unformatDate(item.createdDate)}
                          maxDate={new Date()}
                          onChange={(e) => handlePostedDateChange(e, item)}
                        />
                      </TableCell>
                      <TableCell className="table_cell_payor_name">
                        {item.payerName}
                      </TableCell>
                      <TableCell>ERA</TableCell>
                      <TableCell>{DateFormat(item.chequeDate)}</TableCell>
                      <TableCell className="table_cell_check_number">
                        {item.chequeNumber}
                      </TableCell>
                      <TableCell>
                        $
                        {parseFloat(item.totalAmt, 10).toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                        })}
                      </TableCell>
                      <TableCell>
                        $
                        {parseFloat(item.amount, 10).toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                        })}
                      </TableCell>
                      <TableCell>
                        $
                        {parseFloat(item.unappliedAmt, 10).toLocaleString(
                          "en-US",
                          {
                            minimumFractionDigits: 2,
                          }
                        )}
                      </TableCell>

                      <TableCell>
                        <div className="status_select_container_row">
                          <FormControl className="status_select_row">
                            <Select
                              value={item.depositStatus}
                              onChange={(e) =>
                                handleDepositStatusChange(e, item)
                              }
                              size="small"
                            >
                              <MenuItem value={0}>Unverified</MenuItem>
                              <MenuItem value={1}>Verified</MenuItem>
                              <MenuItem value={2}>Missing</MenuItem>
                            </Select>
                          </FormControl>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            // rowsPerPageOptions={[100, 150, 200, 250, 500]}
            component="div"
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            // onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </div>
      <Dialog
        open={openExport}
        // onClose={handleCloseExport}
        fullWidth={true}
        maxWidth="lg"
      >
        <div className="progressBar">
          <Stepper className="stepper" activeStep={activeStep} alternativeLabel>
            {steps.map((label, index) => {
              const labelProps = {};
              if (stepFailed) {
                if (isStepFailed(index)) {
                  labelProps.error = true;
                }
              }
              return (
                <Step key={label}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </div>

        <div className="export_btn_container">
          <div>
            <Button
              size="small"
              color="primary"
              variant="contained"
              disabled={hideBtn}
              onClick={handleDownload}
              className="download_btn"
            >
              Download
            </Button>
          </div>
          <div className="download_cancel_btn">
            <Button
              size="small"
              color="primary"
              variant="outlined"
              disabled={hideBtn}
              onClick={handleCloseExport}
            >
              Close
            </Button>
          </div>
        </div>
      </Dialog>
    </div>
  );
}

const mapStateToProps = (state) => ({
  bankData: state.bankRecon,
});

const mapDispatchToProps = (dispatch) => ({
  getPayments: (values) => dispatch(getPayments(values)),
  updateStatus: (values) => dispatch(updateStatus(values)),
  updatePostedDate: (values) => dispatch(updatePostedDate(values)),
  exportPayments: (values) => dispatch(exportPayments(values)),
  downloadPayments: (values) => dispatch(downloadPayments(values)),
  getExportStatus: (values) => dispatch(getExportStatus(values)),
});

BankRecon.propTypes = {
  bankData: PropTypes.object,
  getPayments: PropTypes.func,
  updateStatus: PropTypes.func,
  updatePostedDate: PropTypes.func,
  exportPayments: PropTypes.func,
  downloadPayments: PropTypes.func,
  getExportStatus: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(BankRecon);
