import { useEffect, useState, useCallback } from "react";
import validator from "validator";
import dot from "dot-object";

/**  Validator
 *   @fileOverview manages valiadtion of all the forms in the application
 *   @author  Anuragh KP <kpanuragh@gmail.com>
 */
export function useFormInput({
  name,
  validation = "",
  values: formData,
  setValues: setFormData,
  defaultInvalidAttr,
  handleError,
  isResetform,
  setIsResetform,
}) {
  useEffect(() => {
    if (isResetform === true) {
      setIsTouched(false);
      setIsResetform(false);
    }
  }, [isResetform]);

  const formValue = dot.pick(name, formData) || "";
  const [value, setValue] = useState(formValue);
  const [isValid, setIsValid] = useState(true);
  const [isTouched, setIsTouched] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  const [validationRules] = useState(validation);

  const handleValidation = useCallback(() => {
    const isValidData = validate(value, validationRules, formData);
    setIsValid(isValidData);
    handleError(name, isValidData);
  }, [setIsValid, validationRules, name, value, handleError, formData]);

  // watch for external parent data changes
  useEffect(() => {
    if (value !== formValue) {
      setValue(formValue);
      setIsTouched(false);
      setIsFocused(false);
    }
  }, [formValue, value, setValue, setIsFocused, setIsTouched]);

  // validate on value change
  useEffect(() => {
    handleValidation();
  }, [handleValidation, name]);

  // rewrite self and parent's value
  const handleChange = useCallback(
    ({ target }) => {
      const { checked, type } = target;
      const valueData = target.value;
      const newValue = type === "checkbox" ? checked : valueData;
      let data;
      const isNested = name.includes(".");
      if (isNested) {
        dot.override = true;
        data = dot.str(name, newValue, { ...formData });
      } else data = { ...formData, [name]: newValue };

      setValue(newValue);
      setFormData(data);
    },
    [setValue, formData, setFormData, name]
  );

  const handleFocus = useCallback(() => {
    setIsTouched(true);
    setIsFocused(true);
    handleValidation();
  }, [setIsTouched, setIsFocused, handleValidation]);

  const handleBlur = useCallback(() => {
    setIsFocused(false);
  }, [setIsFocused]);
  const showError = !isValid.value && isTouched && !isFocused;
  const opt = { ...defaultInvalidAttr };
  if (isValid.message) opt.helperText = isValid.message;
  const invalidAttr = showError ? opt : null;

  return {
    value,
    name,
    onChange: handleChange,
    onFocus: handleFocus,
    onBlur: handleBlur,
    ...invalidAttr,
  };
}

export function useFormCheckboxGroup({
  name,
  value,
  values: formData,
  setValues: setFormData,
}) {
  const formValue = dot.pick(name, formData) || [];
  const hasValue = formValue.indexOf(value) > -1;

  const [checked, setChecked] = useState(hasValue);

  // watch for external parent data changes
  useEffect(() => {
    const isChecked = formValue.indexOf(value) > -1;
    setChecked(isChecked);
  }, [formValue, value]);

  // rewrite self and parent's value
  const handleChange = useCallback(
    ({ target }) => {
      const oldValue = dot.pick(name, formData) || [];
      const checkedData = target.checked;
      let newValue;

      const index = oldValue.indexOf(value);
      if (checkedData && index < 0) {
        newValue = [...oldValue, value];
      } else if (!checkedData && index > -1) {
        newValue = oldValue.filter((v) => v !== value);
      }

      // using dot helps us change nested values
      let data;
      const isNested = name.includes(".");
      if (isNested) {
        dot.override = true;
        data = dot.str(name, newValue, { ...formData });
      } else {
        data = { ...formData, [name]: newValue };
      }

      setChecked(checkedData);
      setFormData(data);
    },
    [value, formData, setFormData, name]
  );

  return {
    name,
    checked,
    onChange: handleChange,
  };
}

export function useForm(defaultValues, invalidAttr = { error: true }) {
  const [values, setValues] = useState(defaultValues);
  const [mounted, setMounted] = useState(false);
  const [formErrors, setFormErrors] = useState([]);
  const [isResetform, setIsResetform] = useState(false);

  const handleError = useCallback(
    (name, isValid) => {
      const ValidValue = isValid.value;
      const errors = formErrors;
      const index = errors.findIndex((error) => error === name);

      if (!ValidValue) {
        if (index < 0) errors.push(name);
      } else if (index > -1) errors.splice(index, 1);
      setFormErrors(errors);
    },
    [formErrors]
  );

  useEffect(() => {
    setMounted(true);
  }, []);

  const useInput = (name, validation) =>
    useFormInput({
      name,
      validation,
      values,
      setValues,
      defaultInvalidAttr: invalidAttr,
      handleError,
      isResetform,
      setIsResetform,
    });

  const useCheckboxGroup = (name, value) =>
    useFormCheckboxGroup({ name, values, setValues, value });

  return {
    values,
    setValues,
    useInput,
    useCheckboxGroup,
    errors: formErrors,
    isValid: mounted && !formErrors.length,
    isResetform,
    setIsResetform,
  };
}

/**
 * Returns either unmet rule, or null
 * @param value
 * @param validation
 * @returns {*}
 */
export function validate(value, validation) {
  const fields = [];
  let trimmedValidation;
  let validatingFields;
  switch (typeof validation) {
    case "object":
      Object.keys(validation).forEach((property) => {
        fields.push({
          rule: property,
          options: validation[property],
        });
      });
      break;

    case "string":
    default:
      if (!validation.length) return true;
      trimmedValidation = validation.replace(/ /g, "");
      validatingFields = trimmedValidation.split(",");
      validatingFields.forEach((fieldName) => {
        fields.push({
          rule: fieldName,
        });
      });
  }

  let isValid = { value: true, message: null };
  for (
    let validationCounter = 0;
    validationCounter < fields.length;
    validationCounter += 1
  ) {
    const field = fields[validationCounter];
    const { rule, options = null } = field;

    switch (rule.trim()) {
      case "isRequired":
        if (!value) {
          isValid = {
            value: false,
            message: typeof options === "object" ? options.message : null,
          };
        }
        break;
      case "isAfter":
        if (!value) {
          isValid = {
            value: false,
            message: typeof options === "object" ? options.message : null,
          };
        } else if (
          validator.isAfter(value.toString(), options.value.toString())
        ) {
          // let data = dot.pick(options.value, formData) || new Date()
          isValid = {
            value: false,
            message: typeof options === "object" ? options.message : null,
          };
        }

        break;
      default:
        if (isValid) {
          if (options !== null) {
            let result;
            const opt = typeof options === "object" ? options.value : options;
            switch (opt) {
              case true:
                result = !value || validator[rule](value);
                break;
              case false:
                result = !value || !validator[rule](value);
                break;
              default:
                result = !value || validator[rule](value, opt);
            }
            isValid = {
              value: result,
              message: typeof options === "object" ? options.message : null,
            };
          } else isValid = { value: validator[rule](value), message: null };
          break;
        }
    }
    if (isValid.value === false) break;
  }
  return isValid;
}
