export const SidebarData = [
  {
    title: "Dashboard",
    link: "/dashboard",
    value: 0,
  },
  {
    title: "Production Summary",
    link: "/production",
    value: 1,
  },
  {
    title: "Production Detailed",
    link: "/production-detailed",
    value: 2,
  },
  {
    title: "Collection Summary",
    link: "/collection",
    value: 3,
  },
  {
    title: "Collection Detailed",
    link: "/collectionDetailed",
    value: 4,
  },
  {
    title: "Bank Reconciliation",
    link: "/bankRecon",
    value: 5,
  },
  {
    title: "Denial Category",
    link: "/denial",
    value: 6,
  },
  // {
  //   title: "Claims Pending",
  //   link: "/claims",
  //   value: 7,
  // },
];
