// import { DENIAL_CATEGORY_RES } from "Redux/DenialCategory/Denial.types";
import {
  PROD_GROUP_BY_PROVIDER,
  PROD_GROUP_BY_PROVIDER_RES,
  PROD_GROUP_BY_SERVICE,
  PROD_GROUP_BY_SERVICE_RES,
  PRODUCTION_DETAILED,
  PRODUCTION_DETAILED_RES,
  CLAIM_LEVEL,
  CLAIM_LEVEL_RES,
  NOTES,
  NOTES_RES,
  EXPORT_DETAILS,
  EXPORT_DETAILS_RES,
  DOWNLOAD_DETAILS,
  DOWNLOAD_DETAILS_RES,
  GET_EXPORT_STATUS,
  GET_EXPORT_STATUS_RES,
  UPDATE_APMT_TYPE,
  UPDATE_APMT_TYPE_RES,
  DENIAL_CATEGORY,
  DENIAL_CATEGORY_RES,
} from "./Production.types";

export const getProdGroupByProvider = (data) => ({
  type: PROD_GROUP_BY_PROVIDER,
  payload: data,
});

export const getProdGroupByProviderRes = (data) => ({
  type: PROD_GROUP_BY_PROVIDER_RES,
  payload: data,
});

export const getProdGroupByService = (data) => ({
  type: PROD_GROUP_BY_SERVICE,
  payload: data,
});

export const getProdGroupByServiceRes = (data) => ({
  type: PROD_GROUP_BY_SERVICE_RES,
  payload: data,
});

export const getProductionDetailed = (data) => ({
  type: PRODUCTION_DETAILED,
  payload: data,
});

export const getProductionDetailedRes = (data) => ({
  type: PRODUCTION_DETAILED_RES,
  payload: data,
});

export const getDenialDetailed = (data) => ({
  type: DENIAL_CATEGORY,
  payload: data,
});

export const getDenialDetailedRes = (data) => ({
  type: DENIAL_CATEGORY_RES,
  payload: data,
});

export const getClaimLevel = (data) => ({
  type: CLAIM_LEVEL,
  payload: data,
});

export const getClaimLevelRes = (data) => ({
  type: CLAIM_LEVEL_RES,
  payload: data,
});

export const getNotes = (data) => ({
  type: NOTES,
  payload: data,
});

export const getNotesRes = (data) => ({
  type: NOTES_RES,
  payload: data,
});

export const exportDetails = (data) => ({
  type: EXPORT_DETAILS,
  payload: data,
});

export const exportDetailsRes = (data) => ({
  type: EXPORT_DETAILS_RES,
  payload: data,
});

export const downloadDetails = (data) => ({
  type: DOWNLOAD_DETAILS,
  payload: data,
});

export const downloadDetailsRes = (data) => ({
  type: DOWNLOAD_DETAILS_RES,
  payload: data,
});

export const getExportStatus = (data) => ({
  type: GET_EXPORT_STATUS,
  payload: data,
});

export const getExportStatusRes = (data) => ({
  type: GET_EXPORT_STATUS_RES,
  payload: data,
});

export const updateApmtType = (data) => ({
  type: UPDATE_APMT_TYPE,
  payload: data,
});

export const updateApmtTypeRes = (data) => ({
  type: UPDATE_APMT_TYPE_RES,
  payload: data,
});
