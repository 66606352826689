import React, { useEffect, useState } from "react";
import { useForm } from "Hooks/validator";
import { connect } from "react-redux";
import { login } from "Redux/Login/Login.actions";
import PropTypes from "prop-types";
import { Button, TextField } from "@material-ui/core";
import primroseLogo from "../../assets/images/primrose-brand-logo-sm-0.5x.png";
import { useHistory } from "react-router-dom";
import "./style.css";

function Login(props) {
  const history = useHistory();
  const [loginError, setloginError] = useState("");

  const defaultValues = {
    clinicId: null,
    userName: "",
    password: "",
  };

  const customErrorAttribute = {
    error: true,
  };

  const { values, setValues, useInput, isValid } = useForm(
    defaultValues,
    customErrorAttribute
  );

  const handleLogin = (e) => {
    e.preventDefault();
    if (isValid) {
      localStorage.setItem("clinic_id", values.clinicId);
      props.login({
        browserVersion: "Chrome100.0.4896.127",
        device: 1,
        ipAddress: "198.32:34:23",
        clinicId: values.clinicId,
        userName: values.userName,
        password: values.password,
      });
    }
  };

  useEffect(() => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("memoryCash");
    localStorage.removeItem("user_data");
    localStorage.removeItem("clinic_id");
    let clinic_id = JSON.parse(localStorage.getItem("clinic_id"));
    if (clinic_id) {
      setValues({
        ...values,
        ...{
          clinicId: clinic_id,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      props.loginData &&
      props.loginData.loginResponse &&
      props.loginData.loginResponse.response
    ) {
      // if (props.loginData.loginResponse.response === 0) {
      //   let data = props.loginData.loginResponse.response.data;
      //   console.log(data);
      //   localStorage.setItem("clinic_id", data.clinicId);
      //   localStorage.setItem("access_token", data.accessToken);
      //   history.push("/home");
      // }

      const { data } = props.loginData.loginResponse.response;
      let responseCode = props.loginData.loginResponse.response.responseCode;
      if (data && responseCode === 0) {
        localStorage.setItem("access_token", data.accessToken);
        localStorage.setItem(
          "isAdministratorAccess",
          data.isAdministratorAccess
        );
        localStorage.setItem("refreshToken", data.refreshToken);
        localStorage.setItem("userId", data.userId);
        localStorage.setItem("roleId", data.roles[0].roleId);
        localStorage.setItem("role", data.roles[0]);
        localStorage.setItem("page", 0);
        // window.location.href = "/home";
        history.push("/home");
      }
      if (responseCode === 117) {
        setloginError("Login failed. Please enter valid credentials");
      }
      if (responseCode === 159) {
        setloginError(
          "We are working to ensure your account is activated. Due to demand, this is taking longer than we anticipated. We will reach out to you as soon as possible to verify your information and activate the account. Thank you for your patience"
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.loginData.loginResponse.response]);

  return (
    <div className="login__page scrollbarY--custom">
      <header className="login__header">
        <div className="login__header__bar">
          <a href="#0">
            <img
              src={primroseLogo}
              alt="primrose-health-logo"
              className="login__header__logo"
            />
          </a>
        </div>
      </header>

      <main className="login__main">
        <section className="login__section">
          <div>
            <h1 className="login__grid__subheading">Dashboard Sign In</h1>
          </div>

          <div>
            <form className="login__form" onSubmit={handleLogin}>
              <div className="login__inputField">
                <TextField
                  margin="normal"
                  required
                  variant="outlined"
                  type="number"
                  label="Clinic ID"
                  className="dashboardLogin__inputField"
                  {...useInput("clinicId", {
                    isRequired: {
                      value: true,
                      message: "Enter a valid Clinic ID",
                    },
                  })}
                />

                <TextField
                  margin="normal"
                  required
                  autoComplete="email"
                  label="Username"
                  error={loginError ? true : false}
                  type="text"
                  variant="outlined"
                  className="dashboardLogin__inputField"
                  {...useInput("userName", {
                    isRequired: {
                      value: true,
                      message: "Enter a valid Username",
                    },
                  })}
                />

                <TextField
                  margin="normal"
                  required
                  type="password"
                  error={loginError ? true : false}
                  helperText={loginError}
                  label="Password"
                  variant="outlined"
                  className="dashboardLogin__inputField"
                  {...useInput("password", {
                    isRequired: {
                      value: true,
                      message: "Enter a valid Password",
                    },
                  })}
                  autoComplete="current-password"
                />

                <div className="btnWrapper">
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className="login__submitBtn"
                    // onClick={handleLogin}
                  >
                    LOGIN
                  </Button>
                </div>

                <div className="helper_text_wrap">
                  <div className="helper_text_large">Forgot your password?</div>
                  <div className="helper_text_small">
                    Contact account manager to reset password
                  </div>
                </div>
              </div>
            </form>
          </div>
        </section>
      </main>

      <div className="footer">
        <div className="footer_container">
          <div className="box_container">
            <div className="box_heading">
              <a href="#0">
                <img
                  src={primroseLogo}
                  alt="primrose-health-logo"
                  className="footer_logo"
                />
              </a>
            </div>
            <div className="list_addr">Primrose.health</div>
            <div className="list_addr">117 Gemini Cir Ste#403</div>
            <div className="list_addr">Birmingham, AL 35209</div>
            <div className="list_addr">
              <span className="number">(205) 880-7575</span>
            </div>
          </div>
          <div className="box_container">
            <div className="box_heading">COMPANY</div>
            <div className="list_item">
              <a href="https://primrose.health/about.html">About us</a>
            </div>
            <div className="list_item">
              <a href="https://primrose.health/privacy-policy.html">
                Privacy policy
              </a>
            </div>
            <div className="list_item">
              <a href="https://primrose.health/blog.html">Blog</a>
            </div>
            <div className="list_item">
              <a href="https://primrose.health/contact-us.html">Contact us</a>
            </div>
            <div className="list_item">
              <a href="https://primrose.health/portfolio/gallery.html">
                Portfolio
              </a>
            </div>
          </div>

          <div className="box_container">
            <div className="box_heading">SERVICES</div>
            <div className="list_item">
              <a href="https://primrose.health/services/managed-billing.html">
                Managed Billing
              </a>
            </div>
            <div className="list_item">
              <a href="https://primrose.health/services/staffed-billing.html">
                Staffed Billing
              </a>
            </div>
            <div className="list_item">
              <a href="https://primrose.health/services/credentialing.html">
                Credentialing
              </a>
            </div>
            <div className="list_item">
              <a href="https://primrose.health/services/it-support.html">
                IT Support
              </a>
            </div>
            <div className="list_item">
              <a href="https://primrose.health/services/reputation-management.html">
                Reputation Management
              </a>
            </div>
            <div className="list_item">
              <a href="https://primrose.health/services/remote-staffing.html">
                Remote Staffing
              </a>
            </div>
          </div>

          <div className="box_container">
            <div className="box_heading">PRODUCTS</div>
            <div className="list_item">
              <a href="https://primrose.health/products/website-seo.html">
                Website & SEO
              </a>
            </div>
            <div className="list_item">
              <a href="https://primrose.health/products/virtual-visits.html">
                Virtual Visits
              </a>
            </div>
            <div className="list_item">
              <a href="https://primrose.health/products/secure-chat.html">
                Secure Chat
              </a>
            </div>
            <div className="list_item">
              <a href="https://primrose.health/products/patient-marketing.html">
                Patient Marketing
              </a>
            </div>
            <div className="list_item">
              <a href="https://primrose.health/products/online-scheduling.html">
                Online Scheduling
              </a>
            </div>
            <div className="list_item">
              <a href="https://primrose.health/products/payment-portal.html">
                Payment Portal
              </a>
            </div>
          </div>
        </div>
        <div className="footer_note">
          <div className="footer_content">© Primrose 2022-2023</div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  loginData: state.login,
});

const mapDispatchToProps = (dispatch) => ({
  login: (values) => dispatch(login(values)),
});

Login.propTypes = {
  loginData: PropTypes.object,
  login: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
