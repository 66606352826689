/**
 * @fileOverview Manages types of the particular action in the redux
 * @author Merina Joy <merina@tensaw.email>
 * @example export const TYPE_NAME = 'VALUE'
 */
/**
 * @type {string}
 */
export const REQUEST_CANCEL = 'REQUEST_CANCEL'
export const CLINIC_DETAILS = 'CLINIC_DETAILS'
export const CLINIC_DETAILS_RES = 'CLINIC_DETAILS_RES'
export const PROVIDERS_LIST = "PROVIDERS_LIST";
export const PROVIDERS_LIST_RES = "PROVIDERS_LIST_RES";
export const FACILITIES_LIST = "FACILITIES_LIST";
export const FACILITIES_LIST_RES = "FACILITIES_LIST_RES";
export const SERVICES_LIST = "SERVICES_LIST";
export const SERVICES_LIST_RES = "SERVICES_LIST_RES";
export const PRODUCTION_TREND = "PRODUCTION_TREND";
export const PRODUCTION_TREND_RES = "PRODUCTION_TREND_RES";
export const COLLECTION_TREND = "COLLECTION_TREND";
export const COLLECTION_TREND_RES = "COLLECTION_TREND_RES";
export const FORECAST = "FORECAST";
export const FORECAST_RES = "FORECAST_RES";
export const NOT_FILED_CLAIMS = "NOT_FILED_CLAIMS";
export const NOT_FILED_CLAIMS_RES = "NOT_FILED_CLAIMS_RES";
export const RECEIVABLE_NET = "RECEIVABLE_NET";
export const RECEIVABLE_NET_RES = "RECEIVABLE_NET_RES";
export const RECEIVABLE_GROSS = "RECEIVABLE_GROSS";
export const RECEIVABLE_GROSS_RES = "RECEIVABLE_GROSS_RES";