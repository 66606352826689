import {
  LOGIN,
  LOGIN_RES,
  LOGOUT,
  LOGOUT_RES,
} from "./Login.types";

export const login = (params) => ({
  type: LOGIN,
  payload: params,
});

export const loginResponse = (data) => ({
  type: LOGIN_RES,
  payload: data,
});

export const logout = (params) => ({
  type: LOGOUT,
  payload: params,
});

export const logoutResponse = (data) => ({
  type: LOGOUT_RES,
  payload: data,
});
