import { ofType } from "redux-observable";
import { switchMap, map } from "rxjs/operators";
import { ajax } from "rxjs/ajax";
import { ajax as utlAjax } from "Utils";
import environment from "environments/environment";
import { Apis } from "Redux/APIs";
import { LOGIN, LOGOUT } from "./Login.types";
import { loginResponse, logoutResponse } from "./Login.actions";
const { clinicBaseUrl } = environment;
const LoginEpic = {};

LoginEpic.login = (action$) =>
  action$.pipe(
    ofType(LOGIN),
    switchMap((action) =>
      ajax({
        headers: {
          "application-id": 2,
          "Content-Type": "application/json",
        },
        url: `${clinicBaseUrl}/${Apis.Login_login}`,
        method: "POST",
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => loginResponse(response)
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        )
      )
    )
  );

LoginEpic.logout = (action$) =>
  action$.pipe(
    ofType(LOGOUT),
    switchMap((action) =>
      utlAjax({
        headers: {
          "application-id": 2,
          "Content-Type": "application/json",
        },
        url: `${clinicBaseUrl}/${Apis.Login_logout}?refreshToken=${action.payload}`,
        method: "POST",
        body: {},
      }).pipe(map((response) => logoutResponse(response)))
    )
  );

export default LoginEpic;
