import {
  COL_GROUP_BY_PROVIDER,
  COL_GROUP_BY_PROVIDER_RES,
  COL_GROUP_BY_SERVICE,
  COL_GROUP_BY_SERVICE_RES,
  COLLECTION_DETAILED,
  COLLECTION_DETAILED_RES,
} from "./Collection.types";

export const getColGroupByProvider = (data) => ({
  type: COL_GROUP_BY_PROVIDER,
  payload: data,
});

export const getColGroupByProviderRes = (data) => ({
  type: COL_GROUP_BY_PROVIDER_RES,
  payload: data,
});

export const getColGroupByService = (data) => ({
  type: COL_GROUP_BY_SERVICE,
  payload: data,
});

export const getColGroupByServiceRes = (data) => ({
  type: COL_GROUP_BY_SERVICE_RES,
  payload: data,
});

export const getCollectionDetailed = (data) => ({
  type: COLLECTION_DETAILED,
  payload: data,
});

export const getCollectionDetailedRes = (data) => ({
  type: COLLECTION_DETAILED_RES,
  payload: data,
});
