import React, { useState } from "react";
import { createContext } from "react";

export const productionSummaryContext = createContext();

const ProdSummaryContext = ({ children }) => {
  const [period, setPeriod] = useState(1);
  // const [display, setDisplay] = useState(1)
  const [chartProviders, setChartProviders] = useState([]);
  const [chartServices, setChartServices] = useState([]);
  const [providers, setProviders] = useState([
    {
      name: "All providers",
      value: -1,
    },
  ]);
  const [provider, setProvider] = useState(-1);
  const [services, setServices] = useState([
    {
      name: "All services",
      value: -1,
    },
  ]);
  const [service, setService] = useState(-1);
  const [group, setGroup] = useState(1);
  const [rows, setRows] = useState([]);
  const [halfRows, setHalfRows] = useState([]);
  const [totals, setTotals] = useState([]);
  const [halfTotals, setHalfTotals] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [halfChartData, setHalfChartData] = useState([]);
  const [values, setValues] = useState({
    period: 1,
    group: 1,
    provider: -1,
    service: -1,
  });
  return (
    <productionSummaryContext.Provider
      value={{
        period,
        setPeriod,
        chartProviders,
        setChartProviders,
        chartServices,
        setChartServices,
        providers,
        setProviders,
        provider,
        setProvider,
        services,
        setServices,
        service,
        setService,
        group,
        setGroup,
        rows,
        setRows,
        halfRows,
        setHalfRows,
        totals,
        setTotals,
        halfTotals,
        setHalfTotals,
        chartData,
        setChartData,
        halfChartData,
        setHalfChartData,
        values,
        setValues,
      }}
    >
      {children}
    </productionSummaryContext.Provider>
  );
};

export default ProdSummaryContext;
