import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import DatePicker from "components/DatePicker";
import { makeStyles } from "@material-ui/core/styles";
import ArrowRightIcon from "@material-ui/icons/NavigateNextRounded";
import ArrowDownIcon from "@material-ui/icons/ExpandMoreRounded";
import TablePagination from "@material-ui/core/TablePagination";
import FilterIcon from "@material-ui/icons/FilterList";
import { toast } from "react-hot-toast";
import {
  Box,
  Button,
  Checkbox,
  Collapse,
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputLabel,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Select,
  Step,
  StepLabel,
  Stepper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@material-ui/core";
import { getCollectionDetailed } from "Redux/Collection/Collection.actions";
import {
  getClaimLevel,
  getNotes,
  exportDetails,
  downloadDetails,
  getExportStatus,
  updateApmtType,
} from "Redux/Production/Production.actions";
import {
  getProvidersList,
  getServicesList,
  getFacilitiesList,
} from "Redux/Dashboard/Dashboard.actions";
import Notes from "../Notes";
import "./style.css";
import { collectionDetailsContext } from "context/CollectionFilterContext";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "center",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "center",
  },
  variant: "menu",
};

const statuses = [
  // { name: "All Statuses", value: 0 },
  { name: "Visit Completed", value: 1 },
  { name: "Created", value: 2 },
  { name: "Filed", value: 3 },
  { name: "Rejected", value: 4 },
  { name: "Denied", value: 5 },
  { name: "Sec Ready", value: 6 },
  { name: "Sec Pending", value: 7 },
  { name: "Ter Ready", value: 8 },
  { name: "Ter Pending", value: 9 },
  { name: "Pat Balance", value: 10 },
  { name: "Closed", value: 11 },
  { name: "Clar Opened", value: 12 },
  { name: "Clar Closed", value: 13 },
  { name: "On Hold", value: 14 },
];

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: 300,
  },
  indeterminateColor: {
    color: "#f50057",
  },
  selectAllText: {
    fontWeight: 500,
  },
  selectedAll: {
    backgroundColor: "rgba(0, 0, 0, 0.08)",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)",
    },
  },
}));

function Row(props) {
  const { row, claims, icds, services, selectedItem } = props;
  const [open, setOpen] = useState(false);
  const [openNotes, setOpenNotes] = useState(false);

  const handleClick = (claimId) => {
    setOpen(!open);
    props.handleExpand(claimId);
  };

  const handleView = (claimId, provId) => {
    setOpenNotes(true);
    props.handleOpenNotes(claimId, provId);
  };

  const DateFormat = (date) => {
    let d = date.split("-");
    return `${d[1]}/${d[2]}/${d[0]}`;
  };

  const handleCloseNotes = () => {
    setOpenNotes(false);
  };

  const handleApmtType = (e, claimId) => {
    props.handleApmtType(e.target.value, claimId);
  };

  return (
    <>
      <TableRow>
        <TableCell className="arrow_icon">
          <div onClick={() => handleClick(row.claimId)}>
            {open ? <ArrowDownIcon /> : <ArrowRightIcon />}
          </div>
        </TableCell>
        {selectedItem.patient && (
          <TableCell className="table_cell_patient_name">
            {row.spatientName}
          </TableCell>
        )}

        {selectedItem.mrn && <TableCell>{row.smrn}</TableCell>}
        {selectedItem.dos && <TableCell>{DateFormat(row.sdos)}</TableCell>}
        {selectedItem.provider && (
          <TableCell className="table_cell_provider_name">
            {row.sproviderName}
          </TableCell>
        )}
        {selectedItem.service && (
          <TableCell>
            <FormControl className="table_cell_dropdown_wrap">
              <Select
                value={row.serviceId}
                onChange={(e) => handleApmtType(e, row.claimId)}
              >
                <MenuItem value={0}>Other</MenuItem>
                {services &&
                  services.map((item, i) => (
                    <MenuItem key={i} value={item.value}>
                      {item.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </TableCell>
        )}
        {selectedItem.charges && (
          <TableCell>
            $
            {parseFloat(row.dcharges, 10).toLocaleString("en-US", {
              minimumFractionDigits: 2,
            })}
          </TableCell>
        )}
        {selectedItem.payments && (
          <TableCell>
            $
            {parseFloat(row.dpayments, 10).toLocaleString("en-US", {
              minimumFractionDigits: 2,
            })}
          </TableCell>
        )}
        {selectedItem.balance && (
          <TableCell>
            $
            {parseFloat(row.dbalance, 10).toLocaleString("en-US", {
              minimumFractionDigits: 2,
            })}
          </TableCell>
        )}
        {selectedItem.claimStatus && (
          <TableCell className="table_cell_claim_status">
            {row.claimStatus ? statuses[row.claimStatus - 1].name : ""}
          </TableCell>
        )}
        {selectedItem.notes && (
          <TableCell
            className="table_viewBtn"
            onClick={() => handleView(row.claimId, row.iproviderId)}
          >
            View
          </TableCell>
        )}
      </TableRow>

      <TableRow>
        <TableCell className="expand_cell" colSpan={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <div className="expand_container">
              <div className="expand_first_card">
                <div className="expand_card_item">ICD1: {icds.icd1}</div>
                <div className="expand_card_item">ICD2: {icds.icd2}</div>
                <div className="expand_card_item">ICD3: {icds.icd3}</div>
                <div className="expand_card_item">ICD4: {icds.icd4}</div>
                <div className="expand_card_item">ICD5: {icds.icd5}</div>
                <div className="expand_card_item">ICD6: {icds.icd6}</div>
                <div className="expand_card_item">ICD7: {icds.icd7}</div>
                <div className="expand_card_item">ICD8: {icds.icd8}</div>
              </div>
              <div className="expand_second_card scrollbarY--custom">
                <TableContainer
                  component={Paper}
                  elevation={0}
                  className="expand_table_container"
                >
                  <Table stickyHeader aria-label="table">
                    <TableHead className="expand_table_head" elevation={0}>
                      <TableRow>
                        <TableCell className="expand_table_head_cell">
                          DOS
                        </TableCell>
                        <TableCell className="expand_table_head_cell">
                          CPT
                        </TableCell>
                        <TableCell className="expand_table_head_cell">
                          Unit
                        </TableCell>
                        <TableCell className="expand_table_head_cell">
                          Fee
                        </TableCell>
                        <TableCell className="expand_table_head_cell">
                          Billed
                        </TableCell>
                        <TableCell className="expand_table_head_cell">
                          Adjusted
                        </TableCell>
                        <TableCell className="expand_table_head_cell">
                          Ins Paid
                        </TableCell>
                        <TableCell className="expand_table_head_cell">
                          Pat Paid
                        </TableCell>
                        <TableCell className="expand_table_head_cell">
                          Ins Bal
                        </TableCell>
                        <TableCell className="expand_table_head_cell">
                          Pat Bal
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {claims &&
                        claims.map((item, i) => (
                          <TableRow key={i}>
                            <TableCell>{DateFormat(item.fromDate)}</TableCell>
                            <TableCell>{item.cptCode}</TableCell>
                            <TableCell>{item.unit}</TableCell>
                            <TableCell>
                              $
                              {parseFloat(item.fee, 10).toLocaleString(
                                "en-US",
                                {
                                  minimumFractionDigits: 2,
                                }
                              )}
                            </TableCell>
                            <TableCell>
                              $
                              {parseFloat(item.billed, 10).toLocaleString(
                                "en-US",
                                {
                                  minimumFractionDigits: 2,
                                }
                              )}
                            </TableCell>
                            <TableCell>
                              $
                              {parseFloat(item.adjust, 10).toLocaleString(
                                "en-US",
                                {
                                  minimumFractionDigits: 2,
                                }
                              )}
                            </TableCell>
                            <TableCell>
                              $
                              {parseFloat(item.insPaid, 10).toLocaleString(
                                "en-US",
                                {
                                  minimumFractionDigits: 2,
                                }
                              )}
                            </TableCell>
                            <TableCell>
                              $
                              {parseFloat(item.patPaid, 10).toLocaleString(
                                "en-US",
                                {
                                  minimumFractionDigits: 2,
                                }
                              )}
                            </TableCell>
                            <TableCell>
                              $
                              {parseFloat(item.insBalance, 10).toLocaleString(
                                "en-US",
                                {
                                  minimumFractionDigits: 2,
                                }
                              )}
                            </TableCell>
                            <TableCell>
                              $
                              {parseFloat(item.patBalance, 10).toLocaleString(
                                "en-US",
                                {
                                  minimumFractionDigits: 2,
                                }
                              )}
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </div>
          </Collapse>
        </TableCell>
      </TableRow>

      <Dialog
        open={openNotes}
        onClose={() => handleCloseNotes()}
        maxWidth="lg"
        // fullWidth
        className="notes_dialog"
      >
        <DialogContent className="notes_dialogueContent scrollbarY--custom">
          <Notes handleCloseNotes={handleCloseNotes} />
        </DialogContent>
      </Dialog>
    </>
  );
}

function CollectionDetailed(props) {
  const classes = useStyles();
  const clinic_id = localStorage.getItem("clinic_id");
  // const [period, setPeriod] = useState(1);
  const [providers, setProviders] = useState([
    {
      name: "All providers",
      value: -1,
    },
  ]);
  const {
    values,
    setValues,
    provider,
    setProvider,
    services,
    setServices,
    service,
    setService,
    facilities,
    setFacilities,
    facility,
    setFacility,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    period,
    setPeriod,
  } = useContext(collectionDetailsContext);
  const [rows, setRows] = useState([]);
  // const [totals, setTotals] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [totalCount, setTotalCount] = useState(0);
  const [grandTotal, setGrandTotal] = useState({});
  const [claims, setClaims] = useState([]);
  const [icds, setIcds] = useState({});
  const [fileName, setFileName] = useState("");
  const [hideBtn, setHideBtn] = useState(true);
  // const [notes, setNotes] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const isAllSelected =
    statuses.length > 0 && selectedStatus.length === statuses.length;
  // const [values, setValues] = useState({
  //   period: 1,
  //   provider: -1,
  //   service: -1,
  //   startDate: "",
  //   endDate: "",
  // });
  const [selectedItem, setSelectedItem] = useState({
    patient: true,
    mrn: true,
    dos: true,
    provider: true,
    service: true,
    payments: true,
    claimStatus: true,
    notes: true,
  });
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openFilter = Boolean(anchorEl);
  const [headCells, setHeadCells] = useState([
    {
      id: "patient",
      label: "Patient",
    },
    {
      id: "mrn",
      label: "MRN",
    },
    {
      id: "dos",
      label: "DOS",
    },
    {
      id: "provider",
      label: "Provider",
    },
    {
      id: "service",
      label: "Service",
    },
    {
      id: "charges",
      label: "Charges",
    },
    {
      id: "payments",
      label: "Payments",
    },
    {
      id: "balance",
      label: "Balance",
    },
    {
      id: "claimStatus",
      label: "Claim Status",
    },
    {
      id: "notes",
      label: "Notes",
    },
  ]);

  // Download Progress

  const [activeStep, setActiveStep] = useState(0);
  const [stepFailed, setStepFailed] = useState(false);
  const steps = stepFailed
    ? ["Starting", "Exporting", "Extracting", "Failed"]
    : ["Starting", "Exporting", "Extracting", "Completed"];

  const isStepFailed = (step) => {
    return step === 3;
  };

  /* state for 'export' modal */
  const [openExport, setOpenExport] = useState(false);

  const handleOpenExport = () => {
    setOpenExport(true);
    setActiveStep(0);
  };
  const handleCloseExport = () => {
    setOpenExport(false);
    setFileName("");
    setActiveStep(0);
  };

  /******************************* */

  const periods = [
    { name: "Custom", value: 1 },
    { name: "Last 12 months", value: 2 },
  ];

  const formatDate = (date) => {
    if (date === null) {
      return null;
    } else if (date === "") {
      return "";
    }
    const d = new Date(date);
    let month = "" + (d.getMonth() + 1);
    let day = "" + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) {
      month = "0" + month;
    }
    if (day.length < 2) {
      day = "0" + day;
    }
    if (isNaN(year)) {
      return "";
    } else {
      return [year, month, day].join("-");
    }
  };

  const lastYear = (date) => {
    if (date === null) {
      return null;
    } else if (date === "") {
      return "";
    }
    const d = new Date(date);
    let month = "" + (d.getMonth() + 1);
    let day = "" + d.getDate();
    const year = d.getFullYear() - 1;
    if (month.length < 2) {
      month = "0" + month;
    }
    if (day.length < 2) {
      day = "0" + day;
    }
    if (isNaN(year)) {
      return "";
    } else {
      return [year, month, day].join("-");
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (period === 1) {
      props.getCollectionDetailed({
        iclinicId: clinic_id,
        btReportType: 1,
        sstartDate: startDate ? formatDate(startDate) : "",
        sendDate: endDate ? formatDate(endDate) : "",
        iproviderId: provider === -1 ? 0 : provider,
        blLedgerDos: false,
        btexportType: 1,
        btrptType: 1,
        sproviderIds: "",
        serviceId: service === -1 ? 0 : service,
        status:
          selectedStatus.length > 0 && isAllSelected
            ? "0"
            : selectedStatus.length > 0
            ? selectedStatus.map((item) => item.value).join(",")
            : "0",
        // status: 0,
        offset: newPage,
      });
    } else if (period === 2) {
      props.getCollectionDetailed({
        iclinicId: clinic_id,
        btReportType: 1,
        sstartDate: lastYear(new Date()),
        sendDate: formatDate(new Date()),
        iproviderId: provider === -1 ? 0 : provider,
        blLedgerDos: false,
        btexportType: 1,
        btrptType: 1,
        sproviderIds: "",
        serviceId: service === -1 ? 0 : service,
        status:
          selectedStatus.length > 0 && isAllSelected
            ? "0"
            : selectedStatus.length > 0
            ? selectedStatus.map((item) => item.value).join(",")
            : "0",
        // status: 0,
        offset: newPage,
      });
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleOpenNotes = (claim_id, p_id) => {
    props.getNotes({
      clinicId: clinic_id,
      claimId: claim_id,
      providerId: 0,
    });
  };

  const handleExpand = (claim_id) => {
    props.getClaimLevel({
      clinicId: clinic_id,
      claimId: claim_id,
    });
  };

  useEffect(() => {
    let newItem = [];
    Object.keys(selectedItem).forEach((x) => {
      headCells.forEach((j) => {
        if (x === j.id) {
          newItem.push({ ...j, status: selectedItem[x] });
        }
      });
    });
    setHeadCells(newItem);
    newItem = [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItem]);

  const handleFilterCheckbox = (e) => {
    setSelectedItem({ ...selectedItem, [e.target.name]: e.target.checked });
  };

  const handleFilterColumn = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilterColumnClose = () => {
    setAnchorEl(null);
  };

  const handleProviderChange = (event) => {
    setProvider(event.target.value);
  };

  const handleServiceChange = (event) => {
    setService(event.target.value);
  };

  const handleFacilityChange = (event) => {
    setFacility(event.target.value);
  };

  const handleApmtType = (apptId, claimId) => {
    props.updateApmtType({
      clinicId: clinic_id,
      apptTypeId: apptId,
      claimId: claimId,
    });
    handleFilter();
  };

  const handleStatusChange = (event) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      setSelectedStatus(
        selectedStatus.length === statuses.length ? [] : statuses
      );
      return;
    }
    setSelectedStatus(value);
  };

  const handlePeriodChange = (event) => {
    setPeriod(event.target.value);
  };

  // const handleReportChange = (event) => {
  //   setReport(event.target.value);
  // };

  const handleStartDateChange = (e) => {
    setStartDate(e);
  };

  const handleEndDateChange = (e) => {
    setEndDate(e);
  };

  const handleFilter = () => {
    setValues({
      period: period,
      provider: provider === -1 ? 0 : provider,
      service: service === -1 ? 0 : service,
      status:
        selectedStatus.length > 0 && isAllSelected
          ? "0"
          : selectedStatus.length > 0
          ? selectedStatus.map((item) => item.value).join(",")
          : "0",
      // status: 0,
      startDate: startDate,
      endDate: endDate,
    });
    if (period === 1) {
      props.getCollectionDetailed({
        iclinicId: clinic_id,
        btReportType: 1,
        sstartDate: startDate ? formatDate(startDate) : "",
        sendDate: endDate ? formatDate(endDate) : "",
        iproviderId: provider === -1 ? 0 : provider,
        blLedgerDos: false,
        btexportType: 1,
        btrptType: 1,
        sproviderIds: "",
        serviceId: service === -1 ? 0 : service,
        status:
          selectedStatus.length > 0 && isAllSelected
            ? "0"
            : selectedStatus.length > 0
            ? selectedStatus.map((item) => item.value).join(",")
            : "0",
        // status: 0,
        offset: page,
      });
    } else if (period === 2) {
      props.getCollectionDetailed({
        iclinicId: clinic_id,
        btReportType: 1,
        sstartDate: lastYear(new Date()),
        sendDate: formatDate(new Date()),
        iproviderId: provider === -1 ? 0 : provider,
        blLedgerDos: false,
        btexportType: 1,
        btrptType: 1,
        sproviderIds: "",
        serviceId: service === -1 ? 0 : service,
        status:
          selectedStatus.length > 0 && isAllSelected
            ? "0"
            : selectedStatus.length > 0
            ? selectedStatus.map((item) => item.value).join(",")
            : "0",
        // status: 0,
        offset: page,
      });
    }
  };

  const handleDenialReport = () => {
    setValues({
      period: period,
      provider: provider === -1 ? 0 : provider,
      service: service === -1 ? 0 : service,
      status:
        selectedStatus.length > 0 && isAllSelected
          ? "0"
          : selectedStatus.length > 0
          ? selectedStatus.map((item) => item.value).join(",")
          : "0",
      // status: 0,
      startDate: startDate,
      endDate: endDate,
    });
    if (period === 1) {
      props.getCollectionDetailed({
        iclinicId: clinic_id,
        btReportType: 1,
        sstartDate: startDate ? formatDate(startDate) : "",
        sendDate: endDate ? formatDate(endDate) : "",
        iproviderId: provider === -1 ? 0 : provider,
        blLedgerDos: false,
        btexportType: 1,
        btrptType: 1,
        sproviderIds: "",
        serviceId: service === -1 ? 0 : service,
        status: "5",
        offset: page,
      });
    } else if (period === 2) {
      props.getCollectionDetailed({
        iclinicId: clinic_id,
        btReportType: 1,
        sstartDate: lastYear(new Date()),
        sendDate: formatDate(new Date()),
        iproviderId: provider === -1 ? 0 : provider,
        blLedgerDos: false,
        btexportType: 1,
        btrptType: 1,
        sproviderIds: "",
        serviceId: service === -1 ? 0 : service,
        status: "5",
        offset: page,
      });
    }
  };

  const handleExport = () => {
    if (period === 1) {
      props.exportDetails({
        iclinicId: clinic_id,
        btReportType: 1,
        sstartDate: startDate ? formatDate(startDate) : "",
        sendDate: endDate ? formatDate(endDate) : "",
        iproviderId: provider === -1 ? 0 : provider,
        blLedgerDos: false,
        btexportType: 1,
        btrptType: 1,
        sproviderIds: "",
        serviceId: service === -1 ? 0 : service,
        status:
          selectedStatus.length > 0 && isAllSelected
            ? "0"
            : selectedStatus.length > 0
            ? selectedStatus.map((item) => item.value).join(",")
            : "0",
      });
    } else if (period === 2) {
      props.exportDetails({
        iclinicId: clinic_id,
        btReportType: 1,
        sstartDate: lastYear(new Date()),
        sendDate: formatDate(new Date()),
        iproviderId: provider === -1 ? 0 : provider,
        blLedgerDos: false,
        btexportType: 1,
        btrptType: 1,
        sproviderIds: "",
        serviceId: service === -1 ? 0 : service,
        status:
          selectedStatus.length > 0 && isAllSelected
            ? "0"
            : selectedStatus.length > 0
            ? selectedStatus.map((item) => item.value).join(",")
            : "0",
      });
    }
    handleOpenExport();
    setTimeout(() => props.getExportStatus(clinic_id), 5000);
  };

  useEffect(() => {
    if (
      props.prodData &&
      props.prodData.exportResponse &&
      props.prodData.exportResponse.response
    ) {
      if (props.prodData.exportResponse.response.responseCode === 0) {
        let data = props.prodData.exportResponse.response.data.fileName;
        setFileName(data);
        setActiveStep(1);
      } else {
        setActiveStep(3);
        setStepFailed(true);
        setHideBtn(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.prodData.exportResponse.response]);

  useEffect(() => {
    if (
      props.prodData &&
      props.prodData.exportStatus &&
      props.prodData.exportStatus.response
    ) {
      if (props.prodData.exportStatus.response.responseCode === 0) {
        let status = props.prodData.exportStatus.response.data.status;
        if (status === 1) {
          setActiveStep(4);
          setHideBtn(false);
        } else {
          const interval = setInterval(() => {
            if (status === 0) {
              setActiveStep(2);
              props.getExportStatus(clinic_id);
            } else if (status === 1) {
              setActiveStep(4);
              setHideBtn(false);
            }
          }, 5000);
          return () => clearInterval(interval);
        }
      } else {
        setActiveStep(3);
        setStepFailed(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.prodData.exportStatus.response]);

  const handleDownload = () => {
    props.downloadDetails(fileName.toString());
  };

  useEffect(() => {
    if (
      props.prodData &&
      props.prodData.downloadResponse &&
      props.prodData.downloadResponse.response
    ) {
      if (fileName) {
        let blobData = props.prodData.downloadResponse.response;
        let fileType = "text/csv";
        download(blobData, fileName, fileType);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.prodData.downloadResponse.response]);

  const download = (content, fileName, contentType) => {
    var a = document.createElement("a");
    var blob = new Blob([content], {
      type: contentType,
    });
    a.href = URL.createObjectURL(blob);
    a.download = fileName;
    a.click();
    blob = null;
  };

  useEffect(() => {
    if (
      props.prodData &&
      props.prodData.updateApmtResponse &&
      props.prodData.updateApmtResponse.response
    ) {
      if (props.prodData.updateApmtResponse.response.responseCode === 0) {
        toast.success("Appointment Type Updated");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.prodData.updateApmtResponse.response]);

  useEffect(() => {
    props.getProvidersList(clinic_id);
    props.getFacilitiesList(clinic_id);
    props.getServicesList({
      start: 0,
      limit: 100,
    });
    // props.getCollectionDetailed({
    //   iclinicId: clinic_id,
    //   btReportType: 1,
    //   sstartDate: "2020-07-25",
    //   sendDate: "2020-09-05",
    //   iproviderId: 1,
    //   blLedgerDos: false,
    //   btexportType: 1,
    //   btrptType: 1,
    //   sproviderIds: "",
    //   serviceId: 0,
    // });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      props.dashboardData &&
      props.dashboardData.providersList &&
      props.dashboardData.providersList.response
    ) {
      if (props.dashboardData.providersList.response.responseCode === 0) {
        let data = props.dashboardData.providersList.response.data;
        let providersArray = data
          .filter((item) => item.active === 1)
          .map((item) => {
            return {
              name:
                item.firstName + " " + item.middleName + " " + item.lastName,
              value: item.id,
            };
          });
        let newArray = [
          {
            name: "All providers",
            value: -1,
          },
          ...providersArray,
        ];
        setProviders(newArray);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dashboardData.providersList.response]);

  useEffect(() => {
    if (
      props.dashboardData &&
      props.dashboardData.servicesList &&
      props.dashboardData.servicesList.response
    ) {
      if (props.dashboardData.servicesList.response.responseCode === 0) {
        let data = props.dashboardData.servicesList.response.data.results;
        let servicesArray = data.map((item) => {
          return {
            name: item.name,
            value: item.id,
          };
        });
        // let newArray = [
        //   {
        //     name: "All services",
        //     value: -1,
        //   },
        //   ...servicesArray,
        // ];
        setServices(servicesArray);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dashboardData.servicesList.response]);

  useEffect(() => {
    if (
      props.dashboardData &&
      props.dashboardData.facilitiesList &&
      props.dashboardData.facilitiesList.response
    ) {
      if (props.dashboardData.facilitiesList.response.responseCode === 0) {
        let data = props.dashboardData.facilitiesList.response.data;
        let facilitiesArray = data
          .filter((item) => item.active === 1)
          .map((item) => {
            return {
              name: item.locationName + ", " + item.city,
              value: item.id,
            };
          });
        setFacilities(facilitiesArray);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dashboardData.facilitiesList.response]);

  useEffect(() => {
    if (
      props.colData &&
      props.colData.colDetailed &&
      props.colData.colDetailed.response
    ) {
      if (props.colData.colDetailed.response.responseCode === 0) {
        let data = props.colData.colDetailed.response.data;
        setTotalCount(data.totalRecords);
        setRows(data.result.providerSummary);
        setGrandTotal(data.result.reportSummary);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.colData.colDetailed.response]);

  useEffect(() => {
    if (
      props.prodData &&
      props.prodData.claimLevel &&
      props.prodData.claimLevel.response
    ) {
      if (props.prodData.claimLevel.response.responseCode === 0) {
        let data = props.prodData.claimLevel.response.data;
        setClaims(data.cptReportDtoList);
        setIcds(data);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.prodData.claimLevel.response]);

  return (
    <div className="colDetailed_root">
      {/* <div className="heading">Collection Detailed</div> */}

      <div className="colDetailed_searchbar_container">
        <div className="dropdown_wrap_container">
          <FormControl className="dropdown_wrap">
            <InputLabel>Provider</InputLabel>
            <Select value={provider} onChange={handleProviderChange}>
              {providers &&
                providers.map((item, i) => (
                  <MenuItem key={i} value={item.value}>
                    {item.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </div>

        <div className="dropdown_wrap_container">
          <FormControl className="dropdown_wrap">
            <InputLabel>Service</InputLabel>
            <Select value={service} onChange={(e) => handleServiceChange(e)}>
              <MenuItem value={-1}>All services</MenuItem>
              {services &&
                services.map((item, i) => (
                  <MenuItem key={i} value={item.value}>
                    {item.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </div>

        <div className="dropdown_wrap_container">
          <FormControl className="dropdown_wrap">
            <InputLabel>Facility</InputLabel>
            <Select value={facility} onChange={(e) => handleFacilityChange(e)}>
              <MenuItem value={-1}>All locations</MenuItem>
              {facilities &&
                facilities.map((item, i) => (
                  <MenuItem key={i} value={item.value}>
                    {item.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </div>

        <div className="dropdown_wrap_container">
          <FormControl className="dropdown_wrap_status">
            <InputLabel>Claim Status</InputLabel>
            <Select
              multiple
              value={selectedStatus}
              onChange={handleStatusChange}
              // input={<Input />}
              renderValue={(selected) =>
                `${selected.length} of ${statuses.length} selected`
              }
              MenuProps={MenuProps}
            >
              <MenuItem
                value="all"
                classes={{
                  root: isAllSelected ? classes.selectedAll : "",
                }}
              >
                <ListItemIcon>
                  <Checkbox
                    classes={{
                      indeterminate: classes.indeterminateColor,
                    }}
                    checked={isAllSelected}
                    indeterminate={
                      selectedStatus.length > 0 &&
                      selectedStatus.length < statuses.length
                    }
                    color="primary"
                  />
                </ListItemIcon>
                <ListItemText
                  classes={{ primary: classes.selectAllText }}
                  primary="All Statuses"
                />
              </MenuItem>
              {statuses.map((option) => (
                <MenuItem key={option.name} value={option}>
                  <ListItemIcon>
                    <Checkbox
                      checked={
                        selectedStatus.findIndex(
                          (i) => i.name === option.name
                        ) > -1
                      }
                      color="primary"
                    />
                  </ListItemIcon>
                  <ListItemText primary={option.name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>

        <div className="dropdown_wrap_container">
          <FormControl className="dropdown_wrap">
            <InputLabel id="demo-simple-select-label">Period</InputLabel>
            <Select value={period} onChange={(e) => handlePeriodChange(e)}>
              {periods &&
                periods.map((item, i) => (
                  <MenuItem key={i} value={item.value}>
                    {item.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </div>

        {period === 1 ? (
          <>
            <div className="production_date_dropdown">
              <DatePicker
                required
                className="production_datePicker"
                label="From"
                value={startDate}
                maxDate={new Date()}
                onChange={(e) => handleStartDateChange(e)}
              />
            </div>

            <div className="production_date_dropdown">
              <DatePicker
                required
                className="production_datePicker"
                label="To"
                value={endDate}
                maxDate={new Date()}
                onChange={(e) => handleEndDateChange(e)}
              />
            </div>
          </>
        ) : period === 2 ? (
          <></>
        ) : (
          ""
        )}

        <div className="colDetailed_Btn_wrap">
          <div className="filterBtn_wrap">
            <Button
              className="filterBtn"
              variant="contained"
              color="primary"
              onClick={() => handleFilter()}
            >
              Filter
            </Button>
          </div>
          <div className="exportBtn_wrap">
            <Button
              className="exportBtn"
              variant="outlined"
              color="primary"
              onClick={handleExport}
            >
              Export
            </Button>
          </div>
          {/* <div className="reportBtn_wrap">
            <Button
              className="reportBtn"
              variant="outlined"
              color="primary"
              onClick={handleDenialReport}
            >
              Denial Report
            </Button>
          </div> */}

          <div className="filterColumn_icon">
            <IconButton
              id="column-filter"
              aria-controls={openFilter ? "column-menu" : undefined}
              aria-expanded={openFilter ? "true" : undefined}
              aria-haspopup="true"
              onClick={handleFilterColumn}
            >
              <FilterIcon />
            </IconButton>
          </div>

          <Menu
            className="columnMenu"
            id="column-menu"
            MenuListProps={{
              "aria-labelledby": "column-filter",
            }}
            anchorEl={anchorEl}
            open={openFilter}
            onClose={handleFilterColumnClose}
            PaperProps={{
              style: {
                width: "55ch",
              },
            }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
          >
            <FormGroup className="column_filter_container">
              <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={0}>
                  {headCells.map((x, i) => (
                    <Grid item xs={4} key={i}>
                      <FormControlLabel
                        className="column_filter_text"
                        control={<Checkbox size="small" color="primary" />}
                        onChange={(e) => handleFilterCheckbox(e)}
                        name={x.id}
                        label={x.label}
                        checked={x.status}
                        labelPlacement="end"
                      />
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </FormGroup>
          </Menu>
        </div>
      </div>
      <div className="table_container">
        <div className="appsTab__container">
          <TableContainer
            component={Paper}
            elevation={0}
            className="col_table_container"
          >
            <Table stickyHeader size="small" aria-label="table">
              <TableHead className="col_table_head" elevation={0}>
                <TableRow>
                  <TableCell className="col_table_head_cell_filler arrow_icon"></TableCell>
                  {headCells &&
                    headCells
                      .filter((x) => x.status === true)
                      .map((x) => (
                        <TableCell
                          key={x.id}
                          align="left"
                          className={
                            x.id === "patient"
                              ? "col_table_head_cell patient_name"
                              : "col_table_head_cell"
                          }
                        >
                          {x.label}
                        </TableCell>
                      ))}
                  <TableCell className="col_table_head_cell_filler arrow_icon"></TableCell>
                  <TableCell className="col_table_head_cell_filler arrow_icon"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows &&
                  rows.map((row, i) => (
                    <Row
                      key={i}
                      row={row}
                      claims={claims}
                      icds={icds}
                      handleExpand={handleExpand}
                      handleOpenNotes={handleOpenNotes}
                      services={services}
                      handleApmtType={handleApmtType}
                      selectedItem={selectedItem}
                    />
                  ))}

                {rows.length === 0 || JSON.stringify(grandTotal) === "{}" ? (
                  ""
                ) : (
                  <TableRow>
                    <TableCell
                      className="grand_total_name"
                      align="left"
                      colSpan={6}
                    >
                      Grand Total
                    </TableCell>
                    {selectedItem.payments ? (
                      <TableCell className="totals_name">
                        $
                        {parseFloat(
                          grandTotal.dtotalPayments,
                          10
                        ).toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                        })}
                      </TableCell>
                    ) : null}
                    {/* <TableCell className="totals_name"></TableCell> */}
                    <TableCell className="totals_name">
                      {/* $
                      {parseFloat(grandTotal.dtotalCharges, 10).toLocaleString(
                        "en-US",
                        {
                          minimumFractionDigits: 2,
                        }
                      )} */}
                    </TableCell>

                    <TableCell className="totals_name">
                      {/* $
                      {parseFloat(grandTotal.dtotalBalance, 10).toLocaleString(
                        "en-US",
                        {
                          minimumFractionDigits: 2,
                        }
                      )} */}
                    </TableCell>
                    <TableCell className="totals_name"></TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[100, 150, 200, 250, 500]}
            component="div"
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </div>
      <Dialog
        open={openExport}
        // onClose={handleCloseExport}
        fullWidth={true}
        maxWidth="lg"
      >
        <div className="progressBar">
          <Stepper className="stepper" activeStep={activeStep} alternativeLabel>
            {steps.map((label, index) => {
              const labelProps = {};
              if (stepFailed) {
                if (isStepFailed(index)) {
                  labelProps.error = true;
                }
              }
              return (
                <Step key={label}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </div>

        <div className="export_btn_container">
          <div>
            <Button
              size="small"
              color="primary"
              variant="contained"
              disabled={hideBtn}
              onClick={handleDownload}
              className="download_btn"
            >
              Download
            </Button>
          </div>
          <div className="download_cancel_btn">
            <Button
              size="small"
              color="primary"
              variant="outlined"
              disabled={hideBtn}
              onClick={handleCloseExport}
            >
              Close
            </Button>
          </div>
        </div>
      </Dialog>
    </div>
  );
}

const mapStateToProps = (state) => ({
  dashboardData: state.dashboard,
  colData: state.collection,
  prodData: state.production,
});

const mapDispatchToProps = (dispatch) => ({
  getProvidersList: (values) => dispatch(getProvidersList(values)),
  getServicesList: (values) => dispatch(getServicesList(values)),
  getFacilitiesList: (values) => dispatch(getFacilitiesList(values)),
  getCollectionDetailed: (values) => dispatch(getCollectionDetailed(values)),
  getClaimLevel: (values) => dispatch(getClaimLevel(values)),
  getNotes: (values) => dispatch(getNotes(values)),
  exportDetails: (values) => dispatch(exportDetails(values)),
  downloadDetails: (values) => dispatch(downloadDetails(values)),
  getExportStatus: (values) => dispatch(getExportStatus(values)),
  updateApmtType: (values) => dispatch(updateApmtType(values)),
});
CollectionDetailed.propTypes = {
  dashboardData: PropTypes.object,
  colData: PropTypes.object,
  prodData: PropTypes.object,
  getProvidersList: PropTypes.func,
  getServicesList: PropTypes.func,
  getFacilitiesList: PropTypes.func,
  getCollectionDetailed: PropTypes.func,
  getClaimLevel: PropTypes.func,
  getNotes: PropTypes.func,
  exportDetails: PropTypes.func,
  downloadDetails: PropTypes.func,
  getExportStatus: PropTypes.func,
  updateApmtType: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(CollectionDetailed);
