import React, { useState } from "react";
import { createContext } from "react";

export const bankReconContext = createContext();

const BankReconContext = ({ children }) => {
  const clinic_id = localStorage.getItem("clinic_id");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [totalCount, setTotalCount] = useState(0);
  const [fileName, setFileName] = useState("");
  const [hideBtn, setHideBtn] = useState(true);
  const [values, setValues] = useState({
    clinicId: clinic_id,
    offset: page,
    postedFrom: "",
    postedTo: "",
    minAmount: 0,
    maxAmount: 10000000,
    reconStatus: -1,
    payor: 1,
    sortId: 1,
    order: "ASC",
  });
  return (
    <bankReconContext.Provider
      value={{
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        rows,
        setRows,
        page,
        setPage,
        rowsPerPage,
        setRowsPerPage,
        totalCount,
        setTotalCount,
        fileName,
        setFileName,
        hideBtn,
        setHideBtn,
        values,
        setValues,
      }}
    >
      {children}
    </bankReconContext.Provider>
  );
};

export default BankReconContext;
