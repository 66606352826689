import React from "react";
import { ResponsiveBar } from "@nivo/bar";
import "./BarGroup.css";

function BarGroup(props) {
  function numFormatter(num) {
    if (num >= 1000) {
      return (num / 1000).toFixed(1).replace(/\.0$/, "") + "K";
    }
    return num;
  }

  return (
    <div className="chart_container">
      <ResponsiveBar
        data={
          props.period === 1
            ? props.halfChartData
            : props.period === 2
            ? props.chartData
            : ""
        }
        // keys={
        //   props.group === 1
        //     ? ["Vinit Lal", "Randall Gale", "Kelsey Reekie", "Mesha Dancer"]
        //     : [
        //         "Office-New Patient",
        //         "Office-Established",
        //         "Televisit",
        //         "Hospital Followup",
        //         "Echo",
        //         "Stress Echo",
        //         "Hospital Procedure",
        //         "Carotid Doppler",
        //         "Arterial Doppler",
        //         "Follow Up Visit",
        //         "MISC",
        //         "Nuclear Stress test",
        //         "Exercise treadmill stress",
        //         "New Patient",
        //         "Phone",
        //       ]
        // }
        keys={props.group === 1 ? props.providerNames : props.serviceNames}
        indexBy="month"
        margin={{
          top: 30,
          right: 160,
          bottom: 70,
          left: 60,
        }}
        enableLabel={false}
        padding={0.25}
        groupMode="grouped"
        valueScale={{ type: "linear" }}
        indexScale={{ type: "band", round: true }}
        colors={{ scheme: "set3" }}
        borderColor={{
          from: "color",
          modifiers: [["darker", 1.8]],
        }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: "Month",
          legendPosition: "middle",
          legendOffset: 38,
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: "Amount",
          legendPosition: "middle",
          legendOffset: -55,
        }}
        legends={[
          {
            dataFrom: "keys",
            anchor: "bottom-right",
            direction: "column",
            justify: false,
            translateX: 140,
            translateY: 15,
            itemsSpacing: 5,
            itemWidth: 120,
            itemHeight: 15,
            itemDirection: "left-to-right",
            itemOpacity: 0.85,
            symbolSize: 8,
            symbolShape: "circle",
            symbolBorderColor: "rgba(0, 0, 0, .5)",
            effects: [
              {
                on: "hover",
                style: {
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]}
        valueFormat={(value) =>
          `$${Number(value).toLocaleString("en-US", {
            minimumFractionDigits: 2,
          })}`
        }
        role="application"
      />
    </div>
  );
}

export default BarGroup;
