/**
 * @fileOverview Manages types of the particular action in the redux
 * @author Merina Joy <merina@tensaw.email>
 * @example export const TYPE_NAME = 'VALUE'
 */
/**
 * @type {string}
 */
export const REQUEST_CANCEL = "REQUEST_CANCEL";
export const PROD_GROUP_BY_PROVIDER = "PROD_GROUP_BY_PROVIDER";
export const PROD_GROUP_BY_PROVIDER_RES = "PROD_GROUP_BY_PROVIDER_RES";
export const PROD_GROUP_BY_SERVICE = "PROD_GROUP_BY_SERVICE";
export const PROD_GROUP_BY_SERVICE_RES = "PROD_GROUP_BY_SERVICE_RES";
export const PRODUCTION_DETAILED = "PRODUCTION_DETAILED";
export const PRODUCTION_DETAILED_RES = "PRODUCTION_DETAILED_RES";
export const CLAIM_LEVEL = "CLAIM_LEVEL";
export const CLAIM_LEVEL_RES = "CLAIM_LEVEL_RES";
export const NOTES = "NOTES";
export const NOTES_RES = "NOTES_RES";
export const EXPORT_DETAILS = "EXPORT_DETAILS";
export const EXPORT_DETAILS_RES = "EXPORT_DETAILS_RES";
export const DOWNLOAD_DETAILS = "DOWNLOAD_DETAILS";
export const DOWNLOAD_DETAILS_RES = "DOWNLOAD_DETAILS_RES";
export const GET_EXPORT_STATUS = "GET_EXPORT_STATUS";
export const GET_EXPORT_STATUS_RES = "GET_EXPORT_STATUS_RES";
export const UPDATE_APMT_TYPE = "UPDATE_APMT_TYPE";
export const UPDATE_APMT_TYPE_RES = "UPDATE_APMT_TYPE_RES";
export const DENIAL_CATEGORY = "DENIAL_CATEGORY";
export const DENIAL_CATEGORY_RES = "DENIAL_CATRGORY_RES";
