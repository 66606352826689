import React, { useState } from "react";
import { createContext } from "react";

export const prodDetailsContext = createContext();

const ProdContext = ({ children }) => {
  const [values, setValues] = useState({
    period: 1,
    provider: -1,
    service: -1,
    startDate: "",
    endDate: "",
  });
  const [provider, setProvider] = useState(-1);
  const [services, setServices] = useState([]);
  const [service, setService] = useState(-1);
  const [facilities, setFacilities] = useState([]);
  const [facility, setFacility] = useState(-1);
  // const [status, setStatus] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [period, setPeriod] = useState(1);

  return (
    <prodDetailsContext.Provider
      value={{
        values,
        setValues,
        provider,
        setProvider,
        services,
        setServices,
        service,
        setService,
        facilities,
        setFacilities,
        facility,
        setFacility,
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        period,
        setPeriod,
      }}
    >
      {children}
    </prodDetailsContext.Provider>
  );
};

export default ProdContext;
