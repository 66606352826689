import {
  GET_PAYMENTS,
  GET_PAYMENTS_RES,
  UPDATE_STATUS,
  UPDATE_STATUS_RES,
  UPDATE_POSTED_DATE,
  UPDATE_POSTED_DATE_RES,
  EXPORT_PAYMENTS,
  EXPORT_PAYMENTS_RES,
  DOWNLOAD_PAYMENTS,
  DOWNLOAD_PAYMENTS_RES,
  GET_EXPORT_STATUS,
  GET_EXPORT_STATUS_RES,
} from "./BankRecon.types";

export const getPayments = (data) => ({
  type: GET_PAYMENTS,
  payload: data,
});

export const getPaymentsRes = (data) => ({
  type: GET_PAYMENTS_RES,
  payload: data,
});

export const updateStatus = (data) => ({
  type: UPDATE_STATUS,
  payload: data,
});

export const updateStatusRes = (data) => ({
  type: UPDATE_STATUS_RES,
  payload: data,
});

export const updatePostedDate = (data) => ({
  type: UPDATE_POSTED_DATE,
  payload: data,
});

export const updatePostedDateRes = (data) => ({
  type: UPDATE_POSTED_DATE_RES,
  payload: data,
});

export const exportPayments = (data) => ({
  type: EXPORT_PAYMENTS,
  payload: data,
});

export const exportPaymentsRes = (data) => ({
  type: EXPORT_PAYMENTS_RES,
  payload: data,
});

export const downloadPayments = (data) => ({
  type: DOWNLOAD_PAYMENTS,
  payload: data,
});

export const downloadPaymentsRes = (data) => ({
  type: DOWNLOAD_PAYMENTS_RES,
  payload: data,
});

export const getExportStatus = (data) => ({
  type: GET_EXPORT_STATUS,
  payload: data,
});

export const getExportStatusRes = (data) => ({
  type: GET_EXPORT_STATUS_RES,
  payload: data,
});
