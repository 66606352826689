import React, { useEffect, useState } from "react";
import { Button } from "@material-ui/core";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getNotes } from "Redux/Production/Production.actions";
import "./style.css";

function Notes(props) {
  const [notes, setNotes] = useState([]);
  const handleClose = () => {
    props.handleCloseNotes();
  };

  // useEffect(() => {
  //   if (props.notes) {
  //     console.log(props.notes);
  //   }
  // }, [props.notes]);

  useEffect(() => {
    if (
      props.prodData &&
      props.prodData.notes &&
      props.prodData.notes.response
    ) {
      if (props.prodData.notes.response.responseCode === 0) {
        let data = props.prodData.notes.response.data;
        setNotes(data.reverse());
        // console.log(data);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.prodData.notes.response]);

  return (
    <div>
      <div className="notes_heading">
        <div className="notes_heading_text">Notes</div>
        <div className="notes_heading_btn">
          <Button
            className="notes_closeBtn"
            variant="outlined"
            color="primary"
            onClick={() => handleClose()}
          >
            Close
          </Button>
        </div>
      </div>
      <div className="notes_content_container">
        {notes.length !== 0 ? (
          notes.map((item) => (
            <div className="notes_content">
              <div className="notes_text">
                <div>{item.note}</div>
              </div>
              {/* <div className="notes_hideBtn">Hide</div> */}
            </div>
          ))
        ) : (
          <div className="notes_content">
            <div className="notes_text">
              <div className="notes_empty">No notes</div>
            </div>
          </div>
        )}

        {/* <div className="notes_content">
          <div className="notes_text">
            <div className="notes_time">
              10/20/2022 06:30 AM CST - CSR Notes by PRNA
            </div>
            <div className="notes_bullet">
              <div className="notes_bullet_icon_wrap">
                <div className="notes_bullet_icon"></div>
              </div>

              <div className="notes_bullet_text">
                “Primary payment: ERA has been reviewed and verified completed
                and ready to process ”
              </div>
            </div>
          </div>
          <div className="notes_hideBtn">Hide</div>
        </div> */}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  prodData: state.production,
});

const mapDispatchToProps = (dispatch) => ({
  getNotes: (values) => dispatch(getNotes(values)),
});

Notes.propTypes = {
  prodData: PropTypes.object,
  getNotes: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(Notes);
