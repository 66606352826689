import {
  CLINIC_DETAILS_RES,
  PROVIDERS_LIST_RES,
  FACILITIES_LIST_RES,
  SERVICES_LIST_RES,
  PRODUCTION_TREND_RES,
  COLLECTION_TREND_RES,
  FORECAST_RES,
  NOT_FILED_CLAIMS_RES,
  RECEIVABLE_NET_RES,
  RECEIVABLE_GROSS_RES,
} from "./Dashboard.types";

/**
 * @fileOverview Manages the response of action
 * @author  Merina Joy <merina@tensaw.email>
 * @example switch (action.type) {
    case TYPE_NAME: {
      return {
        ...state,
        ...{INITIAL_STATE: action.payload },
      }
    }
    }
 */

const INITIAL_STATE = {
  clinicDetails: {},
  providersList: [],
  facilitiesList: [],
  servicesList: [],
  productionTrend: [],
  collectionTrend: [],
  forecast: [],
  notFiledClaims: [],
  receivableNet: [],
  receivableGross: [],
};

/**
 *
 * @param state - global state management
 * @param action - contains type and payload
 * @returns {{loginResponse: {}, login: boolean, status: boolean}|{loginResponse: *, login: boolean, status: boolean}|{loginResponse: {}, login: boolean, status: *}}
 */

const DashboardReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CLINIC_DETAILS_RES: {
      return { ...state, ...{ clinicDetails: action.payload } };
    }
    case PROVIDERS_LIST_RES: {
      return { ...state, ...{ providersList: action.payload } };
    }
    case FACILITIES_LIST_RES: {
      return { ...state, ...{ facilitiesList: action.payload } };
    }
    case SERVICES_LIST_RES: {
      return { ...state, ...{ servicesList: action.payload } };
    }
    case PRODUCTION_TREND_RES: {
      return { ...state, ...{ productionTrend: action.payload } };
    }
    case COLLECTION_TREND_RES: {
      return { ...state, ...{ collectionTrend: action.payload } };
    }
    case FORECAST_RES: {
      return { ...state, ...{ forecast: action.payload } };
    }
    case NOT_FILED_CLAIMS_RES: {
      return { ...state, ...{ notFiledClaims: action.payload } };
    }
    case RECEIVABLE_NET_RES: {
      return { ...state, ...{ receivableNet: action.payload } };
    }
    case RECEIVABLE_GROSS_RES: {
      return { ...state, ...{ receivableGross: action.payload } };
    }
    default:
      return state;
  }
};

export default DashboardReducer;
